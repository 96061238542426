import React from 'react'
import './SidemenuStyle.css'
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'

const Sidemenu = ({change}) => {

    const [isDisable,setDisable] = useState(false);

    const [isAdminMenu, setAdminMenu] = useState(false);

    const handleClick = (e) => {
        if(localStorage.getItem("test1Started")){
            e.preventDefault();
        }
    }

    useEffect(() => {
        if(localStorage.getItem("test1Started")){
            setDisable(true);
        }
        if(localStorage.getItem("admin") !== null){
            setAdminMenu(true);
        }
        else{
            setAdminMenu(false);
        }
    },[change]);

    return (
        <div className="sidemenu">
            <div className="dashboard_logo">
                <img src="/assets/img/pm_logo_white.png" alt="" />
            </div>

            { isAdminMenu ?

            (<nav>
            <ul className="dashmenu_list mt20">
                <li className="dashmenu mt15">
                    <NavLink onClick={handleClick} to='/admin/admindashboard' className= {`dashlink ${isDisable? 'opacityHalf': ''}`}><img src="/assets/img/icons/Note.png" /> Dashboard</NavLink>
                </li>
                <li className="dashmenu mt15">
                    <NavLink onClick={handleClick} to='/admin/userlist' className= {`dashlink ${isDisable? 'opacityHalf': ''}`}><img src="/assets/img/icons/ChartBar.png" /> Users List</NavLink>
                </li>
            </ul>
            </nav>) :
            (<nav>
                <ul className="dashmenu_list mt20">
                    {/* <li className="dashmenu">
                        <NavLink to='/Dashboard' className="dashlink"><img src="../assets/img/icons/House.png" /> Dashboard</NavLink>
                    </li> */}
                    <li className="dashmenu mt15">
                        <NavLink onClick={handleClick} to='/NewsAndInformation' className= {`dashlink ${isDisable? 'opacityHalf': ''}`}><img src="../assets/img/icons/Note.png" /> News and Information</NavLink>
                    </li>
                    <li className="dashmenu mt15">
                        <NavLink onClick={handleClick} to='/Dataset' className= {`dashlink ${isDisable? 'opacityHalf': ''}`}><img src="../assets/img/icons/ChartBar.png" /> Data Set</NavLink>
                    </li>
                    <li className="dashmenu mt15">
                        <NavLink to='/DecisionRound' className= {`dashlink`}><img src="../assets/img/icons/Target.png" /> Decision Round</NavLink>
                    </li>
                    <li className="dashmenu mt15">
                        <NavLink to='/Result' className= {`dashlink `}><img src="../assets/img/icons/result.png" /> Results</NavLink>
                    </li>
                    {/* <li className="dashmenu mt15">
                        <NavLink to='/#' className="dashlink"><img src="../assets/img/icons/Bell.png" /> Notification <span className="notification">3</span></NavLink>
                    </li>
                    <li className="dashmenu mt15">
                        <NavLink to='/#' className="dashlink"><img src="../assets/img/icons/Gear.png" /> Settings</NavLink>
                    </li> */}
                </ul>
            </nav>)

            }

        </div>
    )
}

export default Sidemenu;