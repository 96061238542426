import React, { useEffect, useState } from "react";
import DashboardHeader from "../../Components/Dashboard_Header/DashboardHeader";
import Dashboard_popup from "../../Components/Dashboard_popup/Dashboard_popup";
import Sidemenu from "../../Components/SideMenu/Sidemenu";
import { clearData, getNewsAndInfoData, infoSelector,updateNewsInfoClicks } from "../../reducers/informationSlice";
import { useDispatch, useSelector } from "react-redux";
import "./NewsAndInformationStyle.css";
import PageLoader from "../../Components/loader/PageLoader";

const NewsAndInformation = () => {
  const [isTermOpen, setTermOpen] = useState(true);
  const [loadingData,setLoadingData] = useState(true)
  const dispatch = useDispatch();
  const { isFetching, isError, errorMessage, newsData } = useSelector(infoSelector);

  useEffect(() => {
    setTermOpen(localStorage.getItem("resultId"));
    dispatch(getNewsAndInfoData());
    dispatch(clearData());
  }, []);

  const [isiinerboard, set_isinnerboard] = useState(false);

  const [toggleState, setToggleState] = useState(1);
  const [current,setCurrent] =useState("")

  const toggletab = (index,id) => {
   
    setLoadingData(false)
    if(current!==id){setCurrent(id); dispatch(updateNewsInfoClicks(id))}
    setToggleState(index);
  };

  const handleChange = (i,id) => {
  
     dispatch(updateNewsInfoClicks(id))
    set_isinnerboard(true);
    setToggleState(i);
   
  };

  return (
    <>
      {!isTermOpen && <Dashboard_popup />}
      {isFetching && loadingData && <PageLoader />}

      <Sidemenu />
      <DashboardHeader title="News and Information" />

      {newsData.length == 0 && (<div className="newsSection">
        "Not permitted to access data"
      </div>)}

      <div className={`newsSection ${isiinerboard ? "hide" : ""}`}>
        {newsData.map((news, i) => (
          <div className="product" onClick={() => handleChange(i + 1,news._id)} key={news._id}>
            <div className="product-top" style={{ background: `url(${news.backgroundImg})` }}>
              <span className="notify">{news.type}</span>
              <img src={news.iconImg} alt="" className="icon" />
            </div>
            <div className="product-bottom">
              <p className="text-3">{news.name}</p>
              <span className="dots"></span>
            </div>
          </div>
        ))}
      </div>

      <div className={`newsExppore ${isiinerboard ? "" : "hide"}`}>
        <div className="innerSideMenu">
          <ul className="inner-side-menu">
            {newsData.map((news, i) => (
              <li className={toggleState == (i + 1) ? "active" : ""} key={news._id} >
                <a onClick={() => toggletab(i + 1,news._id)}>
                  {news.name}{" "}
                  <img src="../assets/img/icons/ArrowRight.png" alt="" />
                </a>
              </li>)
            )}
          </ul>
        </div>

        <div className="innerboard-wrapper">

          {newsData.map((news, i) => (
            <div className={`innerboard ${toggleState == (i + 1) ? "active-tab" : ""}`} key={news._id}>
              <ul className="board-point">
                {news?.information.map((info, i) => (
                  <li className={i == 0 ? '' : 'mt30'} key={i}>
                    <img src="../assets/img/icons/PolygonArrowRight.png" alt="" />
                    <div className="main-point">
                      <h3 className="heading-3">
                        {info.title}
                      </h3>
                      <p className="text-3 text-lightblack mt10 preLine">
                        {info.description.split('/n').map((item, key) => {
                          return <React.Fragment key={key}>{item}<br /></React.Fragment>
                        })}
                      </p>
                    </div>
                  </li>
                ))}

                {news?.products.map((pro, i) => (
                  <li className={i == 0 ? '' : 'mt30'} key={i}>
                    <img src="../assets/img/icons/PolygonArrowRight.png" alt="" />
                    <div className="main-point">
                      <h3 className="heading-3">
                        {pro.productName}
                      </h3>
                      <p className="text-3 text-lightblack mt10 preLine">
                        {pro.description.split('/n').map((item, key) => {
                          return <React.Fragment key={key}>{item}<br /></React.Fragment>
                        })}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NewsAndInformation;
