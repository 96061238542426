import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let token;

function getToken() {
    token = localStorage.getItem("token");
}

export const startTimer = createAsyncThunk(
    "timer/startTimer",
    async (_, thunkAPI) => {
        try {
            getToken();
            const response = await fetch(
                `${environment.baseURL}/api/startTimer`,

                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getTimerValue = createAsyncThunk(
    "timer/getTimerValue",
    async (resultId, thunkAPI) => {
        try {
            getToken();
            const response = await fetch(
                `${environment.baseURL}/api/getTimerValue/${resultId}`,

                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

function setTime(dte) {
    let diff = new Date().getTime() - new Date(dte).getTime();

    var diffHrs = Math.floor((diff % 86400000) / 3600000); 
    var diffMins = Math.round(((diff % 86400000) % 3600000) / 60000);
    var diffSecs = Math.round((diff % 60000) / 1000);
    
    return {diffHrs,diffMins,diffSecs};
}

export const timerSlice = createSlice({
    name: "timer",
    initialState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
        min: 0,
        sec: 0,
        hour: 0,
        running: false,
        resultId: null
    },
    reducers: {
        setRunning: (state) => {
            state.running = true;
            return state;
        },
        addSec: (state) => {
            state.sec += 1;
            return state;
        },
        addMin: (state) => {
            state.min += 1;
            return state;
        },
        addHour: (state) => {
            state.hour += 1;
            return state;
        },
        clearSec: (state) => {
            state.sec = 0;
            return state;
        },
        clearMin: (state) => {
            state.min = 0;
            return state;
        }, clearHour: (state) => {
            state.hour = 0;
            return state;
        },
        clearTimers: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.min =0;
            state.hour =0;
            state.sec =0;
            state.running = false;
            state.resultId = null;

            return state;
        },
    },
    extraReducers: {
        [startTimer.fulfilled]: (state, { payload }) => {
            state.resultId = payload;
            state.isFetching = false;
            state.running = true;
        },
        [startTimer.pending]: (state) => {
            state.isFetching = true;
        },
        [startTimer.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? 'Something Went Wrong';
        },
        [getTimerValue.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            let {diffHrs,diffMins,diffSecs} =setTime(payload);
            state.hour = diffHrs;
            state.min = diffMins;
            state.sec = diffSecs;
            state.running = true;
        },
        [getTimerValue.pending]: (state) => {
            state.isFetching = true;
        },
        [getTimerValue.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? 'Something Went Wrong';
        },
    },
});

export const { addSec, addMin, addHour, setRunning, clearHour, clearMin, clearSec, clearTimers, clearData } = timerSlice.actions;

export const timerSelector = (state) => state.timer;