import React, { useEffect } from 'react'

const ErrorToast = (props) => {
    useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                props.setShow(false);
            }, 2000);
        }
    }, [props.show])
    return (
        <div className={`danger-toast ${props.show ? "active" : ""}`}>
            <img src="/assets/img/icons/errorIcon.svg" alt="" />
            <p>{props.message}</p>
        </div>
    )
}

export default ErrorToast