import React from 'react'
import './TimeInfoPopupStyle.css';

const TimeInfoPopup = ({onAgree}) => {
    return (
        <div className={`popup_start start_round_popup`}>
            <div className="popup_wrapper">
                <div className="popup">

                    <div className="dashheader">
                        <h3 className="heading-4"><img src="../assets/img/icons/XCircle.png" alt="" /> Time Limit</h3>
                    </div>

                    <div className="start_round_wrapper">
                        <h2 className="text-3 mt20">There is a limit of <b>5 minutes</b> on this decision round!</h2>

                        <div className="start_round_buttton">
                            <button className='table_btn_fill' onClick={onAgree}>Continue</button>
                        </div>

                    </div>



                </div>
            </div>
        </div>
    )
}

export default TimeInfoPopup