import { configureStore } from "@reduxjs/toolkit";
import { adminSlice } from "../adminSlice";
import { dataSetSlice } from "../dataSetSlice";
import { informationSlice } from "../informationSlice";
import { questionSlice } from "../questionSlice";
import { timerSlice } from "../timerSlice";
import { userSlice } from "../userSlice";

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    mockQuestions: questionSlice.reducer,
    information: informationSlice.reducer,
    timer: timerSlice.reducer,
    dataSet: dataSetSlice.reducer,
    admin: adminSlice.reducer,
  },
});
