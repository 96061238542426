import React, { useState } from "react";
import "./Dashboard-popupStyle.css";
import "../../Pages/login/LoginStyle.css";
import { useDispatch } from "react-redux";
import { startTimer, timerSelector } from "../../reducers/timerSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Dashboard_popup = () => {
  const [isPopUp, setIsPopUpOpen] = useState(true);
  const dispatch = useDispatch();
  const { resultId } = useSelector(timerSelector);

  const onClickNextButton = () => {
    dispatch(startTimer());
  };

  useEffect(() => {
    if (resultId !== null) {
      setIsPopUpOpen(false);
      localStorage.setItem("resultId",resultId );
    }
  }, [resultId]);

  return (
    <div className={`popup_start welcome_popup ${isPopUp ? "" : "hidepopup"}`}>
      <div className="popup_wrapper">
        <div className="popup">
          <p className="text-4 text-black">
            Hi, Welcome to your new role as a PM Manager in{" "}
            <a href="#" className="forget-link text-blue">
              Booking.com
            </a>
            .
          </p>

          <p className="text-3 text-lightblack mt20">
            The company has been looking forward to a rockstar Product Manager
            who can help us resolve a problem that we have recently observed
          </p>

          <p className="text-3 text-lightblack mt20">
            The director is convinced that with your expertise in problem
            solving, you are the best person who can help identify the problem
          </p>

          <p className="text-3 text-lightblack mt20">
            The following pages will have a list of different information that
            we have gathered that can help you. There are responses from
            different teams on what they have been upto and might be relevant in
            reaching a conclusion. There is also some data that the analytics
            team was able to gather that should help you uncover the root of the
            problem
          </p>

          <p className="text-3 text-lightblack mt20">
            Feel free to deep dive across all the data and activities. Take as
            much time as you want. However, once you reach a conclusion, the
            director will only allocate 5 minutes for you to provide a decision
            along with a brief explanation of your conclusion
          </p>

          <p className="text-3 text-lightblack mt20">
            Remember, at this stage we are only asking you to identify the
            source of the problem.
          </p>

          <p className="text-3 text-lightblack mt20">
            COVID NOTE: The following data sets have been cleaned up to remove
            the noise caused by impact of Covid across the ecosystem. You can
            assume no impact due to Covid during your assessment
          </p>

          <p className="text-3 text-lightblack mt20">
            Good luck! We are sure you will deliver on this.
          </p>

          <button className="blue-Submit mt30" onClick={onClickNextButton}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard_popup;
