import React from 'react'
import './UserDetailsPopupStyle.css';
import { useState } from 'react';

const UserDetailsPopup = ({ isShow, close, clickNumber, setClickNumber, data }) => {

    // const [ clickNumber, setClickNumber ] = useState(1);
    function msToTime(duration) {
        var milliseconds = Math.floor((duration % 1000) / 100),
            seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return (hours > 0 ? hours + " hrs " : '') + (minutes > 0 ? minutes + " mins " : '') + (seconds > 0 ? seconds + " secs " : "");
    }

    return (

        <div className={`popup_start userdetail-popup ${isShow ? '' : 'hidepopup'}`}>
            <div className="popup_wrapper">

                <div className="popup">

                    <div className="dashheader userdetail-popup-header">

                        <div className="rounds-count">
                            {data?.length > 0 && data.map((d) =>
                                (<p className={`text-3 round-count-btn ${d._id === clickNumber ? 'active' : ''}`} onClick={() => setClickNumber(d._id)}>Round {d._id}</p>))}
                        </div>

                        <button className="close" onClick={close}><img src="/assets/img/icons/X.png" alt="" /></button>
                    </div>

                    <div className="round-detail-wrapper datasettable datatable-admin">

                        {data?.length > 0 && data.map((d) => (
                            <table className={`result_table ${d._id == clickNumber ? 'active' : ''}`}>
                                <thead>
                                    <th className='text-left'>Round</th>
                                    <th className='text-right'>Time Taken</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-left'>Discovery Round</td>
                                        <td className='text-right'>{d?.avgTimeDiscovery ? msToTime(d?.avgTimeDiscovery) : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-left'>Decision Round</td>
                                        <td className='text-right'>{d?.avgTimeDicision ? msToTime(d?.avgTimeDicision): "-"}</td>
                                    </tr>
                                </tbody>
                            </table>))}
                    </div>

                </div>

            </div>
        </div>

    )
}

export default UserDetailsPopup