import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const AdminRoute = () => {
  let loc = useLocation();
  const auth = null; // determine if authorized, from context or however you're doing it
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return localStorage.getItem("admin") ? (loc.pathname == "/admin"? (<Navigate to="/admin/admindashboard" />): <Outlet />) : <Navigate to="/admin/login" />;
};
