import React from 'react'
import './Submit_Answer_Popup_Style.css';

const Submit_Answer_Popup = ({onCancel,onAgree}) => {
  return (
    <div className={`popup_start start_round_popup`}>
            <div className="popup_wrapper">
                <div className="popup">

                    <div className="dashheader">
                        <h3 className="heading-4"><img src="../assets/img/icons/XCircle.png" alt="" /> Submit Answer</h3>
                    </div>

                    <div className="start_round_wrapper">
                        <h2 className="text-3 mt20">Are you Sure? Once submitted you <b>won’t</b> be able to change your preferences later </h2>

                        <div className="start_round_buttton">
                            <button className='table_btn' onClick={onCancel}>No</button>
                            <button className='table_btn_fill' onClick={onAgree}>Yes</button>
                        </div>

                    </div>



                </div>
            </div>
        </div>
  )
}

export default Submit_Answer_Popup