import React from 'react'
import DashboardHeader from '../../Components/Dashboard_Header/DashboardHeader'
import Sidemenu from '../../Components/SideMenu/Sidemenu'

import './DashboardStyle.css'

const dashboard = (props) => {
    return (
        <>
            <Sidemenu />
            <DashboardHeader title="Dashboard"/>
        </>
    )
}

export default dashboard;