import React, { useState } from 'react'
import './ResultPopStyle.css';

const ResultPop = (props) => {

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    if (props.resultData == null) {
        return;
    }

    console.log(props.resultData);

    function msToTime(duration) {
         var seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return (hours > 0 ? hours + " hrs " : '') + (minutes > 0 ? minutes + " mins " : '') + (seconds + " secs ");
    }

    return (
        <div className={`popup_start result_popup adminResult ${props.show ? 'open' : ''} `}>
            <div className="popup_wrapper">
                <div className="popup">
                    <div className="dashheader">
                        <div className="pop-toggle-button">
                            <h3 className={toggleState === 1 ? "tabs heading-4 active" : "tabs heading-4"} onClick={() => toggleTab(1)}>Result view</h3>
                            <h3 className={toggleState === 2 ? "tabs heading-4 active" : "tabs heading-4"} onClick={() => toggleTab(2)}>Data Information</h3>
                        </div>
                        <button className="close">
                            <img src="/assets/img/icons/X.png" alt="" onClick={() => props.setShow(val => !val)} />
                        </button>
                    </div>

                    <div className={toggleState === 1 ? "content result_partition_wrapper active" : "content result_partition_wrapper"}>
                        <div className="result_partition_left">
                            {props.resultData.answer1 && (<div className="result_wrapper mt20">

                                <div className="result_header">
                                    <h3 className="heading-4">Please choose the top 3 reasons for the drop in market share for Booking.com.</h3>
                                </div>

                                <div className="result_score_point mt20">
                                    <p className="text-3">{props.resultData?.answer1.selectedPos1.decisionTitle}</p>
                                    <span className="score_point">{props.resultData?.answer1.scorePos1} Points</span>
                                    <div className="progress_bar mt10">
                                        <div className="progress" style={{ width: props.resultData?.answer1.scorePos1 * 10 + '%' }}></div>
                                    </div>

                                </div>

                                <div className="result_score_point mt20">
                                    <p className="text-3">{props.resultData?.answer1.selectedPos2.decisionTitle}</p>
                                    <span className="score_point">{props.resultData?.answer1.scorePos2} Points</span>
                                    <div className="progress_bar mt10">
                                        <div className="progress" style={{ width: props.resultData?.answer1.scorePos2 * 10 + '%' }}></div>
                                    </div>

                                </div>

                                <div className="result_score_point mt20">
                                    <p className="text-3">{props.resultData?.answer1.selectedPos3.decisionTitle}</p>
                                    <span className="score_point">{props.resultData?.answer1.scorePos3} Points</span>
                                    <div className="progress_bar mt10">
                                        <div className="progress" style={{ width: props.resultData?.answer1.scorePos3 * 10 + '%' }}></div>
                                    </div>

                                </div>

                            </div>)}

                            {props.resultData?.answer3.map((ans) =>
                            (<div className="result_wrapper answer_wrapper mt20" key={ans._id}>

                                <div className="result_header">
                                    <h3 className="heading-4">{ans.decisionQuestionId.decisionTitle}</h3>
                                    <span className="score_point">{ans.score} Points</span>
                                </div>

                                <div className="result_score_point mt20">
                                    <span className="score_point bg_green">1 Position</span>
                                    <p className="text-3">{ans.position1.ansTitle}</p>
                                </div>

                                <div className="result_score_point">
                                    <span className="score_point bg_yellow">2 Position</span>
                                    <p className="text-3">{ans.position2.ansTitle}</p>
                                </div>

                                <div className="result_score_point">
                                    <span className="score_point bg_red">3 Position</span>
                                    <p className="text-3">{ans.position3.ansTitle}</p>
                                </div>

                            </div>))}
                        </div>

                        <div className="result_partition_right">

                            <div className="score_board mt20">
                                <div className="result_header">
                                    <h3 className="heading-4">Total Number</h3>
                                </div>

                                <div className="score_board_progress">
                                    <div className="score_board_details">
                                        <p className="text-3 text-darkblue">Decision Round</p>
                                        <h3 className="heading-3">{props.resultData?.totalScorePercent}%</h3>
                                    </div>
                                    <div className="progress_bar mt10"><div className="progress" style={{ width: props.resultData?.totalScorePercent + '%' }}></div></div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className={toggleState === 2 ? "content result_partition_wrapper active" : "content result_partition_wrapper"}>

                        {props.resultData?.roundInfo.map((item,index)=>(
                        <div className="datatinfo-popup mt20" key={index} >
                            <div className="rounds-count"><p className="text-3 round-count-btn active">Round {item.roundNumber}</p></div>
                            <div className="datainfo-body">
                                <table className="result_table">
                                    <thead>
                                        <th className="text-left">News and Information</th>
                                        <th className="text-right">No. of Clicks</th>

                                    </thead>
                                    <tbody>
                                        {item?.newsInfoClicks?.map((val, index) => (

                                            <tr key={index}>
                                                <td className="text-left">{val?.newsInfoId.name}</td>
                                                <td className="text-right">{val.count}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <table className="result_table">
                                    <thead>
                                        <th className="text-left">Data Set</th>
                                        <th className="text-right">No. of Clicks</th>
                                    </thead>
                                    <tbody>
                                        {item?.dataSetClicks?.map((res, index) => (

                                            <tr key={index}>
                                                <td className="text-left">{res?.dataSetId.name}</td>
                                                <td className="text-right">{res.count}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>

                                <table className="result_table">
                                    <thead>
                                        <th className="text-left">Rounds</th>
                                        <th className="text-right">No. of Clicks</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-left">Discovery Round</td>
                                            <td className="text-right">{item?.timeTakenDiscovery !== undefined ?msToTime(item.timeTakenDiscovery):"-"}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left">Decision Round</td>
                                            <td className="text-right">{item?.timeTakenDicision !== undefined ? msToTime(item.timeTakenDicision) : '-'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                       ))}

                    </div>

                </div>
            </div>
        </div>
    )
}

export default ResultPop