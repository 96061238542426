import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";


let token,resultId

function getToken(){
  token = localStorage.getItem("token");
  resultId = localStorage.getItem("resultId")
}
// Decision Round Questions
export const getDataSetInfo = createAsyncThunk(
  "dataSets/getDataSetInfo",
  async (obj, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(
        `${environment.baseURL}/api/getDataSet`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// graphData /getMarketData/:dataSetId/:region
export const getMarketData = createAsyncThunk(
  "dataSets/getMarketData",
  async (obj, thunkAPI) => {
    try {
      getToken();
      let dataSetId=obj.dataSetId
      let region=obj.region
      let newObj={
        category:obj?.category,
        company:obj?.company,
        marketing:obj?.marketing,
        month:obj?.month
      }
      const response = await fetch(
        `${environment.baseURL}/api/getMarketData/${dataSetId}/${region}`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(newObj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// dataSet Filter /api/getDatasetFilters/62ce71ed602c8475d7516379
export const getDataSetFilters = createAsyncThunk(
  "dataSets/getDataSetFilters",
  async (obj, thunkAPI) => {
    try {
      getToken();
      let dataSetId=obj.dataSetId
      const response = await fetch(
        `${environment.baseURL}/api/getDataSetFilters/${dataSetId}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateDataSetClicks =createAsyncThunk(
  "newsClicks/updateDataSetClicks",
  async (id, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(
        `${environment.baseURL}/api/updateDataSetClicks/${resultId}/${id}`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.isSuccess;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
)

export const dataSetSlice = createSlice({
  name: "dataSet",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    dataSetOuterData: [],
    marketData:[],
    getFilterData:[]
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
    clearData: (state) => {
      state.dataSetOuterData = [];

      return state;
    }
  },
  extraReducers: {
    [getDataSetInfo.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.dataSetOuterData = payload;
    },
    [getDataSetInfo.pending]: (state) => {
      state.isFetching = true;
    },
    [getDataSetInfo.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
    },
    [getMarketData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.marketData = payload;
    },
    [getMarketData.pending]: (state) => {
      state.isFetching = true;
    },
    [getMarketData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
    },
    [getDataSetFilters.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getFilterData = payload;
    },
    [getDataSetFilters.pending]: (state) => {
      state.isFetching = true;
    },
    [getDataSetFilters.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
    },
    [updateDataSetClicks.fulfilled]: (state) => {
      console.log("clicked");
    },
    [updateDataSetClicks.pending]: (state) => {
     console.log("pending")
    },
    [updateDataSetClicks.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
    },

  },
});

export const { clearState,clearData } = dataSetSlice.actions;

export const dataSelector = (state) => state.dataSet;