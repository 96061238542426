import React from 'react'
import './Start_Round_Popup_Style.css';

const Start_Round_Popup = ({onAgree, onCancel}) => {
    return (
        <div className={`popup_start start_round_popup`}>
            <div className="popup_wrapper">
                <div className="popup">

                    <div className="dashheader">
                        <h3 className="heading-4"><img src="../assets/img/icons/XCircle.png" alt="" /> Start Round</h3>
                    </div>

                    <div className="start_round_wrapper">
                        <h2 className="text-3 mt20"><b>Are you sure?</b><br/>Once you start this round, you <b>won’t</b> be able to access the information</h2>

                        <div className="start_round_buttton">
                            <button className='table_btn'onClick={onCancel} >No</button>
                            <button className='table_btn_fill' onClick={onAgree}>Yes</button>
                        </div>

                    </div>



                </div>
            </div>
        </div>
    )
}

export default Start_Round_Popup