import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let token;

function getToken() {
  token = localStorage.getItem("admin");
}

export const loginadmin = createAsyncThunk(
  "admin/login",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(`${environment.baseURL}/admin/loginAdmin`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        localStorage.clear();
        localStorage.setItem("admin", data?.Data?.token);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "admin/getAllUsers",
  async (obj, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(`${environment.baseURL}/admin/getAllUsers`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getparticularUserAns = createAsyncThunk(
  "admin/getparticularUserAns",
  async (obj, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(`${environment.baseURL}/admin/getparticularUserAns/${obj}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getDashBoardData = createAsyncThunk(
  "admin/getDashBoardData",
  async (obj, thunkAPI) => {
    try {
      getToken();
      const min = obj?.min ?? ""
      const max = obj?.max ?? ""
      const minDate = obj?.minDate ?? ""
      const maxDate = obj?.maxDate ?? ""

      const response = await fetch(`${environment.baseURL}/admin/getDashBoardData?scoreLower=${min}&scoreUpper=${max}&dateLower=${minDate}&dateUpper=${maxDate}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    dashBoardData: null,
    userlist: [],
    userlistparticular: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [loginadmin.fulfilled]: (state, { payload }) => {
      //   state.email = payload.email;
      //   state.adminname = payload.name;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [loginadmin.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
    },
    [loginadmin.pending]: (state) => {
      state.isFetching = true;
    },


    [getAllUsers.fulfilled]: (state, { payload }) => {
      //   state.email = payload.email;
      //   state.adminname = payload.name;
      state.isFetching = false;
      state.isSuccess = true;
      state.userlist = payload.Data;
      return state;
    },
    [getAllUsers.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
    },
    [getAllUsers.pending]: (state) => {
      state.isFetching = true;
    },


    [getparticularUserAns.fulfilled]: (state, { payload }) => {
      //   state.email = payload.email;
      //   state.adminname = payload.name;
      state.isFetching = false;
      state.isSuccess = true;
      state.userlistparticular = payload.Data;
      return state;
    },
    [getparticularUserAns.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
    },
    [getparticularUserAns.pending]: (state) => {
      state.isFetching = true;
    },

    [getDashBoardData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.dashBoardData = payload.Data;
      return state;
    },
    [getDashBoardData.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
    },
    [getDashBoardData.pending]: (state) => {
      state.isFetching = true;
    },

  },
});

export const { clearState } = adminSlice.actions;

export const adminSelector = (state) => state.admin;
