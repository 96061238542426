import React, { useEffect, useState } from "react";
import Sidemenu from "../../Components/SideMenu/Sidemenu";
import DashboardHeader from "../../Components/Dashboard_Header/DashboardHeader";
import "./DecisionRoundStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearResult,
  getDecisionRoundQuestions,
  getParticularDecisionData,
  getTestTimer,
  questionSelector,
  resetGame,
  saveAnsOnTimeOut,
  saveUserAnswer1,
  saveUserAnswer3,
  startGame
} from "../../reducers/questionSlice";

import ParagraphQuestionComponent from "./ParagraphQuestionComponent";
import ParticularQuestionComponent from "./ParticularQuestionComponent";

import DragDropComponent from "./DragDropComponent";
import PageLoader from "../../Components/loader/PageLoader";
import Start_Round_Popup from "../../Components/Start_Round_Popup/Start_Round_Popup";
import Submit_Answer_Popup from "../../Components/Submit_Answer_Popup/Submit_Answer_Popup";
import Text_Submitted_Popup from "../../Components/Text_Submitted_Popup/Text_Submitted_Popup";
import ErrorToast from "../../Components/Toast/ErrorToast";
import { clearTimers, setRunning } from "../../reducers/timerSlice";
import TimeInfoPopup from "../../Components/TimeInfoPopup/TimeInfoPopup";

const DecisionRound = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [change, setchange] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currQuestion, setCurrQuestion] = useState('');
  const [isFirstSubmit, setFirstSubmit] = useState(false);

  const [showErr, setShowErr] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const { isFetching, isError, errorMessage, specificDecisiosnData, test1Started, options, savedAns1, savedAns, isGameResetted, gameTimer } =
    useSelector(questionSelector);
  useEffect(() => {
    if (localStorage.getItem("test1Started")) {
      setCurrQuestion('question1');
      if (localStorage.getItem("savedAns1") || localStorage.getItem("savedAns2") || localStorage.getItem("savedAns")) {
        return;
      }
      dispatch(getTestTimer(localStorage.getItem("resultId")));
    } else {
      setIsStart(true);
    }
  }, []);

  useEffect(() => {
    if (test1Started) {
      localStorage.setItem("test1Started", test1Started);
      setchange((prev) => !prev);
      setCurrQuestion('question1');
    }
  }, [test1Started]);

  useEffect(() => {
    if (isGameResetted) {
      dispatch(clearResult());
      // dispatch(setRunning());
      navigate('/NewsAndInformation');
    }
  }, [isGameResetted]);

  useEffect(() => {
    if (currQuestion !== 'question1') {
      return;
    }
    let x = 0;
    if (localStorage.getItem("savedAns1")) {
      setCurrQuestion('question2');
      x = 1;
    }
    if (localStorage.getItem("savedAns2")) {
      setCurrQuestion('question3');
      x = 1;
    }
    if (localStorage.getItem("savedAns")) {
      setIsSubmitted(true);
      x = 1;
    }
    if (x == 0) {
      dispatch(getDecisionRoundQuestions());
    }
  }, [currQuestion]);

  useEffect(() => {
    if (savedAns1) {
      localStorage.setItem("savedAns1", savedAns1);
      setCurrQuestion('question2');
    }
  }, [savedAns1]);

  useEffect(() => {
    setQuestionOptions(options);
  }, [options]);

  useEffect(() => {
    if (savedAns) {
      localStorage.setItem("savedAns", savedAns);
      dispatch(clearTimers());
      setIsSubmitted(true);
    }
  }, [savedAns]);

  // const [isresultPopup, setResultPopup] = useState(false);

  //answers state 
  const [answer1, setAnswer1] = useState(null);
  const [answer3, setAnswer3] = useState(null);


  const handleNextClick = () => {

    if (answer1 == null || answer1.position1 == '' || answer1.position2 == '' || answer1.position3 == '') {
      setShowErr(true);
      setErrMessage("Please fill all the 3 positions");
      return;
    }
    setFirstSubmit(true);
    // setCurrQuestion('question2');
    // dispatch(saveUserAnswer1({ answer1 }));
  }

  const onSubmit = () => {
    if (answer3 && answer3.every((ans) => ans.position1 != '' && ans.position2 != '' && ans.position3 != '')) {
      dispatch(saveUserAnswer3({ obj: { answer3 }, resultId: localStorage.getItem("resultId") }));
    } else {
      setShowErr(true);
      setErrMessage("Please fill all the positions before submittion");
    }
  }

  useEffect(() => {
    if (currQuestion === 'question3') {
      dispatch(getParticularDecisionData(localStorage.getItem("resultId")));
    }
  }, [currQuestion])

  const onStartRound = () => {
    setTimerPopup(false);
    setNewRunning(true);
    dispatch(startGame(localStorage.getItem("resultId")));
  }

  const onPlayAgain = () => {
    localStorage.removeItem("resultId");
    localStorage.removeItem("test1Started");
    localStorage.removeItem("savedAns2");
    localStorage.removeItem("savedAns");
    localStorage.removeItem("savedAns1");

    setIsSubmitted(false);
    dispatch(resetGame());
    setCurrQuestion('')
  }

  const [newMin, setNewMin] = useState(4);
  const [newSec, setNewSec] = useState(59);
  const [newRunning, setNewRunning] = useState(false);

  const [isTimerPopup, setTimerPopup] = useState(false);

  function setTime(dte) {
    let diff = new Date(new Date(dte).getTime() + (5 * 60000)) - new Date().getTime();
    console.log("diff", diff);
    if (diff < 0) {
      return { Mins: 0, Secs: 0 };
    }
    var Mins = Math.round(((diff % 86400000) % 3600000) / 60000);
    var Secs = Math.round((diff % 60000) / 1000);

    return { Mins, Secs };
  }

  useEffect(() => {
    if (newMin == 0 && newSec == 0 && currQuestion == "question1" && !localStorage.getItem("savedAns1")) {
      dispatch(saveAnsOnTimeOut(localStorage.getItem("resultId")));
    }
  }, [newMin, newSec]);

  useEffect(() => {
    if (gameTimer !== null) {
      let { Mins, Secs } = setTime(gameTimer);
      if (Mins == 0 && Secs == 0 && !localStorage.getItem("savedAns1") && currQuestion == 'question1') {
        setNewMin(0);
        setNewSec(0);
        dispatch(saveAnsOnTimeOut(localStorage.getItem("resultId")));
        return;
      }
      setNewMin(Mins);
      setNewSec(Secs);
      setNewRunning(true);
    }
  }, [gameTimer]);

  useEffect(() => {
    let interval;
    if (newRunning) {
      interval = setInterval(() => {
        setNewSec((prevTime) => prevTime - 1);
        if (newSec === 0) {
          setNewMin((prevTime) => prevTime - 1);
          setNewSec(59);
        }
        if (newMin === 0 && newSec === 0) {
          console.log("Session Expire");
          setNewRunning(false);
          setNewMin(0);
          setNewSec(0);
        }
      }, 1000);
    } else if (!newRunning) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  });


  return (
    <>
      {/* Popups Here */}
      {isTimerPopup && (<TimeInfoPopup onAgree={onStartRound} />)}
      {isStart && (<Start_Round_Popup onAgree={() => { setIsStart(false); setTimerPopup(true) }} onCancel={() => navigate(-1)} />)}
      {isFirstSubmit && (<Submit_Answer_Popup onAgree={() => {
        dispatch(saveUserAnswer1({ obj: { answer1 }, resultId: localStorage.getItem('resultId') })); setFirstSubmit(false)
      }}
        onCancel={() => setFirstSubmit(false)} />)}
      {isSubmitted && (<Text_Submitted_Popup onPlayAgain={onPlayAgain} onViewResult={() => navigate('/result')} />)}

      {/* toast */}
      <ErrorToast show={showErr} message={errMessage} setShow={setShowErr} />

      {/* preloader */}
      {isFetching && <PageLoader />}
      <Sidemenu change={change} />
      <DashboardHeader title="Decision Round" />

      {/* <Result_popup isresultPopup={isresultPopup} setResultPopup={setResultPopup} resultData={usersAnswer} /> */}

      <ParagraphQuestionComponent show={currQuestion == 'question2'} setCurrQuestion={setCurrQuestion} />
      <ParticularQuestionComponent show={currQuestion == 'question3'} setCurrQuestion={setCurrQuestion}
        setAnswer3={setAnswer3} answer3={answer3} questionsData={specificDecisiosnData} onSubmit={onSubmit}
      />
      <div className={`decision-wrapper ${currQuestion == 'question1' ? '' : 'hide'}`}>
        <div className="decision-header">
          <h3 className="heading-6 text-darkblue">
            Please choose the top 3 reasons for the drop in market share for Booking.com. <br/>You have to drag the reasons (shown on left) to the respective Position Boxes (shown on right)
          </h3>
          <spam className="timer">
            Timer:{" "}
            <i>
              {newMin < 10 ? "0" + newMin : newMin} :{" "}
              {newSec < 10 ? "0" + newSec : newSec}
            </i>
          </spam>
        </div>

        <DragDropComponent options={questionOptions} setAnswer={setAnswer1} />


      </div>
      {currQuestion == 'question1' && (<div className="decision-buttons">
        <button className="blue-Submit" onClick={handleNextClick}>Next</button>
      </div>)}
    </>
  );
};

export default DecisionRound;
