import React from 'react'
// import Slider from 'rc-slider';
// import Range from 'rc-slider';
 import MultiRangeSlider from "multi-range-slider-react";
import ReactSlider from "react-slider"
import './ScorePopupStyle.css';
import { useState } from 'react';

const ScorePopup = (props) => {

   
   
  return (
    <div className={`popup_start scorePopup ${props.show? 'open' : '' }`}>
        <div className="popup_wrapper">
            <div className="popup">

            <div class="dashheader">
                <h3 class="heading-4">Score (% Percentage)</h3>
                <button class="close"><img src="/assets/img/icons/X.png" alt="" onClick={() => props.setShow(val=>!val)}/></button>
            </div>

            <div className='popup-body'>
            <MultiRangeSlider
			min={0}
			max={100}
			step={5}
			minValue={props.minValue}
			maxValue={props.maxValue}
			onInput={(e) => {
				props.onChange(e);
			}}
		/>
    {/* <ReactSlider defaultValue={[minValue,maxValue]} className="horizontal-slider" trackClassName='tracker' min={0} max={100} minDistance={10} step={10} pearling={true} withTracks={true} renderThumb={(props)=>{return <div {...props} className="thumb"> </div>}}  renderTrack={(props)=>{return <div {...props} className="track"> </div>}} /> */}
            </div>

            <div className="popup-footer">
                <div class="start_round_buttton">
                    <button class="table_btn_fill" onClick={props.onApply}>Apply</button>
                </div>
            </div>

            </div>
        </div>
    </div>
  )
}

export default ScorePopup