import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";


let token;

function getToken(){
  token = localStorage.getItem("token");
}
// Decision Round Questions
export const getDecisionRoundQuestions = createAsyncThunk(
  "questions/getDecisionRoundQuestions",
  async (obj, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(
        `${environment.baseURL}/api/getAllDecisionData`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getParticularDecisionData = createAsyncThunk(
  "questions/getParticularDecisionData",
  async (id, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(
        `${environment.baseURL}/api/getParticularDecisionData/${id}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const saveUserAnswer1 = createAsyncThunk(
  "questions/saveUserAnswer1",
  async ({obj,resultId}, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(`${environment.baseURL}/api/saveUserAnswer1/${resultId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const saveUserAnswer2 = createAsyncThunk(
  "questions/saveUserAnswer2",
  async ({obj,resultId}, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(`${environment.baseURL}/api/saveUserAnswer2/${resultId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const saveUserAnswer3 = createAsyncThunk(
  "questions/saveUserAnswer3",
  async ({obj,resultId}, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(`${environment.baseURL}/api/saveUserAnswer3/${resultId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const saveAnsOnTimeOut = createAsyncThunk(
  "questions/saveAnsOnTimeOut",
  async (resultId, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(`${environment.baseURL}/api/saveAnsOnTimeOut/${resultId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUsersAnswer = createAsyncThunk(
  "questions/getUsersAnswer",
  async (_, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(
        `${environment.baseURL}/api/getUsersAnswer`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAnswer2 = createAsyncThunk(
  "questions/getAnswer2",
  async (resultId, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(
        `${environment.baseURL}/api/getAnswer2/${resultId}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getTestTimer = createAsyncThunk(
  "questions/getTestTimer",
  async (id, thunkAPI) => {
      try {
          getToken();
          const response = await fetch(
              `${environment.baseURL}/api/getTestTimer/${id}`,

              {
                  method: "GET",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: token,
                  },
              }
          );
          let data = await response.json();

          if (response.status === 200) {
              return data.Data;
          } else {
              return thunkAPI.rejectWithValue(data);
          }
      } catch (error) {
          return thunkAPI.rejectWithValue({ error: error.message });
      }
  }
);

export const startGame = createAsyncThunk(
  "questions/startGame",
  async (resultId, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(
        `${environment.baseURL}/api/startGame/${resultId}`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const resetGame = createAsyncThunk(
  "questions/resetGame",
  async (_, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(
        `${environment.baseURL}/api/resetGame`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const questionSlice = createSlice({
  name: "mockQuestions",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    options: [],
    specificDecisiosnData: [],
    isTimeExpire: false,
    test1Started: false,
    savedAns1:false,
    savedAns2: false,
    savedAns: false,
    usersAnswer: null,
    answer2: null,
    isGameResetted: false,
    gameTimer:null
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
    clearResult: (state) => {
      state.savedAns1 = false;
      state.savedAns2 = false;
      state.savedAns = false;
      state.isTimeExpire = false;
      state.usersAnswer = null;
      state.answer2 = null;
      state.isGameResetted = false;
      state.gameTimer=null;
      state.test1Started = false;

      return state;
    }
  },
  extraReducers: {
    [getDecisionRoundQuestions.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.options = payload;
    },
    [getDecisionRoundQuestions.pending]: (state) => {
      state.isFetching = true;
    },
    [getDecisionRoundQuestions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
    [saveUserAnswer1.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.savedAns1 = true;
      state.isTimeExpire = payload.Data;
    },
    [saveUserAnswer1.pending]: (state) => {
      state.isFetching = true;
    },
    [saveUserAnswer1.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
    [saveUserAnswer2.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.savedAns2 = true;
    },
    [saveUserAnswer2.pending]: (state) => {
      state.isFetching = true;
    },
    [saveUserAnswer2.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
    [saveUserAnswer3.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.savedAns = true;
    },
    [saveUserAnswer3.pending]: (state) => {
      state.isFetching = true;
    },
    [saveUserAnswer3.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
    [saveAnsOnTimeOut.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.savedAns = true;
    },
    [saveAnsOnTimeOut.pending]: (state) => {
      state.isFetching = true;
    },
    [saveAnsOnTimeOut.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
    [getParticularDecisionData.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.specificDecisiosnData = payload;
    },
    [getParticularDecisionData.pending]: (state) => {
      state.isFetching = true;
    },
    [getParticularDecisionData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
    [getUsersAnswer.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.usersAnswer = payload;
    },
    [getUsersAnswer.pending]: (state) => {
      state.isFetching = true;
    },
    [getUsersAnswer.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
    [getAnswer2.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.answer2 = payload;
    },
    [getAnswer2.pending]: (state) => {
      state.isFetching = true;
    },
    [getAnswer2.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
    [startGame.fulfilled]: (state, { payload }) => {
      state.test1Started = true;
      state.isFetching = false;
    },
    [startGame.pending]: (state) => {
      state.isFetching = true;
    },
    [startGame.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
    [resetGame.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isGameResetted = true;
    },
    [resetGame.pending]: (state) => {
      state.isFetching = true;
    },
    [resetGame.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
    [getTestTimer.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.gameTimer = payload;
    },
    [getTestTimer.pending]: (state) => {
      state.isFetching = true;
    },
    [getTestTimer.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? 'Something Went Wrong';
    },
  },
});

export const { clearState,clearResult } = questionSlice.actions;

export const questionSelector = (state) => state.mockQuestions;
