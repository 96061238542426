import React, { useEffect, useState } from 'react'
import './ResultStyle.css';
import DashboardHeader from "../../Components/Dashboard_Header/DashboardHeader";
import Sidemenu from "../../Components/SideMenu/Sidemenu";
import Result_popup from '../../Components/Result_popup/Result_popup';
import { getUsersAnswer, questionSelector } from '../../reducers/questionSlice';
import PageLoader from '../../Components/loader/PageLoader';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const Result = () => {
    const [isresultPopup, setResultPopup] = useState(false);
    const [resultData, setResultData] = useState(null);
    const [percentile, setPercentile] = useState(null);

    const dispatch = useDispatch();
    const { isFetching, usersAnswer } =
        useSelector(questionSelector);

    useEffect(() => {
        dispatch(getUsersAnswer());
    }, []);

    const handlePercentile = (ans) => {
        let allAns = usersAnswer?.allAns;
        let countBelow = allAns.filter(x => x?.totalScorePercent < ans?.totalScorePercent).length;
        let percentile = (countBelow / allAns.length) * 100;
        setPercentile(percentile);
    }

    return (<>
        {isFetching && <PageLoader />}
        <Sidemenu />
        <DashboardHeader title="Result" />
        <Result_popup isresultPopup={isresultPopup} setResultPopup={setResultPopup} resultData={resultData} percentile={percentile}/>
        <div className="result_page_wrapper">

            <div className="decision-header"><h3 className="heading-6 text-darkblue">List of Results</h3></div>

            {(usersAnswer?.decisions && usersAnswer?.decisions?.length > 0) ? (<div className="datasettable">
                <table className='result_table'>
                    <thead>
                        <th>Attempts</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Score</th>
                        <th> </th>
                    </thead>
                    <tbody>
                        {usersAnswer?.decisions.map((ans, index) =>
                            (<tr key={ans._id}>
                                <td>Attempt {index+1}</td>
                                {ans?.submittedAt ?(<td>{moment(ans?.submittedAt).format("DD MMMM, YYYY")}</td>): (<td>-</td>)}
                                {ans?.submittedAt ?(<td>{moment(ans?.submittedAt).format("hh:mm A")}</td>): (<td>-</td>)}
                                {ans?.totalScorePercent !== null ?(<td>{ans?.totalScorePercent}%</td>): (<td>-</td>)}
                                {ans?.totalScorePercent !== 0 ? (<td className='table_btn_wrapper'> <button className='table_btn'
                                onClick={() => {setResultPopup(true); setResultData(ans); handlePercentile(ans)}}>View Result</button> </td>): (<td className='table_btn_wrapper'>NA</td>)}
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>) : (

            <div className="no-answer mt10"><h3 className="heading-6">You haven’t attended any exam yet!</h3></div>)}

        </div>

    </>

    )
}

export default Result