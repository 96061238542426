import React, { useEffect, useState } from "react";
import "./DecisionRoundStyle.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnswer2,
  questionSelector, saveUserAnswer2,
} from "../../reducers/questionSlice";
import ErrorToast from "../../Components/Toast/ErrorToast";

const ParagraphQuestionComponent = ({ show, setCurrQuestion }) => {

  const dispatch = useDispatch();
  const { isFetching, isSuccess, isError, errorMessage, savedAns2, answer2 } =
    useSelector(questionSelector);

  const [decisionAns, setDecisionAns] = useState('');
  const [showErr, setShowErr] = useState(false);


  useEffect(() => {
    if (show && localStorage.getItem("savedAns2")) {
      dispatch(getAnswer2(localStorage.getItem("resultId")));
    }
  }, [show]);

  useEffect(() => {
    if (answer2 !== null) {
      setDecisionAns(answer2);
    }
  }, [answer2]);

  useEffect(() => {
    if (savedAns2) {
      localStorage.setItem("savedAns2", savedAns2);
    }
  }, [savedAns2]);

  const handleNextClick = () => {
    if (decisionAns === '') {
      setShowErr(true);
      return;
    }
    dispatch(saveUserAnswer2({ obj: { answer2: { answerGiven: decisionAns } }, resultId: localStorage.getItem("resultId") }));
    setCurrQuestion('question3');

  }

  return (
    <>
      <ErrorToast show={showErr} message= 'Please provide some answer first!' setShow={setShowErr} />

      <div className={`decision_next_wrapper ${show ? '' : 'hide'}`}>
        <div className="decision-header">
          <h3 className="heading-6 text-darkblue">
            Explain the reason of the order in 300 words or less. You can focus on the top 3 choices made
          </h3>
        </div>

        <div className="decision-questions">
          <div className="decision_imput">
            <form >
              <textarea onChange={(e) => setDecisionAns(e.target.value)} rows={10} placeholder="Write Something" value={decisionAns}></textarea>
            </form>
          </div>
        </div>

        <div className="decision-buttons">
          {/* <button className="blue-Submit" onClick={() => setCurrQuestion('question1')}>Back</button> */}
          <button className="blue-Submit" onClick={handleNextClick}>Next</button>
        </div>
      </div>
    </>
  );
};

export default ParagraphQuestionComponent;
