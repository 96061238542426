import React from 'react'
import { useState } from 'react';

const TotalClickPopup = ({isShowClicks,closeClicks,roundNumber,setRoundNumber,data}) => {

    // const [ newroundNo, setNewRoundNo ] = useState(1);

    return (
        <div className={`popup_start userdetail-popup ${isShowClicks ? '' : 'hidepopup'}`}>
            <div className="popup_wrapper">

                <div className="popup">

                    <div className="dashheader userdetail-popup-header">

                        <div className="rounds-count">
                        {data?.length > 0 && data.map((d) =>
                                (<p className={`text-3 round-count-btn ${d._id === roundNumber ? 'active' : ''}`} onClick={() => setRoundNumber(d._id)}>Round {d._id}</p>))}
                        </div>

                        <button className="close" onClick={closeClicks}><img src="/assets/img/icons/X.png" alt="" /></button>
                    </div>

                    <div className="round-detail-wrapper datasettable datatable-admin">

                        {data?.length > 0 && data.map((d) => (
                            <table className={`result_table ${d._id == roundNumber ? 'active' : ''}`}>
                                <thead>
                                    <th className='text-left'>Discovery Round</th>
                                    <th className='text-right'>No. of Clicks</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-left'>News and Information</td>
                                        <td className='text-right'>{d?.newsInfoClicks ? d?.newsInfoClicks: "-"}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-left'>Data Set</td>
                                        <td className='text-right'>{d?.dataSetClicks ? d?.dataSetClicks : "-"}</td>
                                    </tr>
                                </tbody>
                            </table>))}

                    </div>

                </div>

            </div>
        </div>
    )
}

export default TotalClickPopup