// require('dotenv').config();

// function Environment() {

//   console.log("rhvirhvirv");
//   if (process.env.NODE_ENV == 'development') {
//     return process.env.REACT_APP_API_DEV;
//   } else {
//     return process.env.REACT_APP_API_PROD;
//   }

// };

// export default Environment;

export const environment = {
  production: true,
  // baseURL: "http://localhost:33000",
  // baseURL: "http://localhost:330000020",
  baseURL: "https://apisimulator.mastersunion.org"
};


