import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/login/Login";
import Dashboard from "./Pages/dashboard/Dashboard";
import "./App.css";

import DataSet from "./Pages/dataSet/DataSet";
import DecisionRound from "./Pages/decisionRound/DecisionRound";
import NewsAndInformation from "./Pages/NewsAndInformation/NewsAndInformation";
import { PrivateRoute } from "./helpers/PrivateRoute";
import Result from "./Pages/result/Result";
import AdminDashboard from "./admin/Pages/AdminDashboard/AdminDashboard";
import { AdminRoute } from "./helpers/AdminRoute";
import AdminLogin from "./admin/Pages/AdminLogin/AdminLogin";
import UserList from "./admin/Pages/UserList/UserList";
import UserDetail from "./admin/Pages/UserDetail/UserDetail";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>

          <Route exact path="/login" element={<Login />} />

          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/" element={<NewsAndInformation />} />
            <Route
              exact
              path="/NewsAndInformation"
              element={<NewsAndInformation />}
            />
            <Route exact path="/DataSet" element={<DataSet />} />
            <Route exact path="/DecisionRound" element={<DecisionRound />} />
            <Route exact path="/Result" element={<Result />} />
          </Route>

          <Route exact path="/admin/login" element={<AdminLogin/>} />

          <Route exact path="/admin" element={<AdminRoute/>}>
            <Route exact path="admindashboard" element={<AdminDashboard />} />
            <Route exact path="userlist" element={<UserList />} />
            <Route exact path="userlist/userdetail/:userId" element={<UserDetail />} />
          </Route>

        </Routes>
      </Router>
    </div>
  );
}

export default App;
