import React, { useState } from "react";
import { useEffect } from "react";
import "./DashHeaderStyle.css";
import { useNavigate } from "react-router-dom";
import { addHour, addMin, addSec, clearMin, clearSec, getTimerValue, timerSelector } from "../../reducers/timerSlice";
import { useSelector, useDispatch } from "react-redux";

const DashboardHeader = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTimer,setIsTimer] = useState(true);

  const onClickLogout = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    if (localStorage.getItem("resultId") && sec == 0 && !localStorage.getItem("savedAns")) {
      dispatch(getTimerValue(localStorage.getItem('resultId')));
    }
    if(localStorage.getItem("admin") !== null){
      setIsTimer(false);
    }
  }, []);

  let { running, sec, min, hour } = useSelector(timerSelector);

  // Timer
  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        dispatch(addSec());
        if (sec === 59) {
          dispatch(addMin());
          dispatch(clearSec());
        } else if (min === 59) {
          dispatch(addHour());
          dispatch(clearSec());
          dispatch(clearMin());
        }
      }, 1000);
    } else if (!running) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  });

  return (
    <div className="dashheader">
      <h3 className="heading-4">{title}</h3>
      <div className="timerWrapper">
        {isTimer && (<spam className="timer">
          {/* Timer */}
          Timer: <i>{hour < 10 ? "0" + hour : hour} : {min < 10 ? "0" + min : min} : {sec < 10 ? "0" + sec : sec}</i>
        </spam>)}

        <button className="table_btn_fill" onClick={onClickLogout}>Logout</button>
      </div>
    </div>
  );
};

export default DashboardHeader;
