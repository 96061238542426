import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";


let token,resultId

function getToken(){
  token = localStorage.getItem("token");
  resultId = localStorage.getItem("resultId")
}
// Decision Round Questions
export const getNewsAndInfoData = createAsyncThunk(
  "questions/getNewsAndInfoData",
  async (obj, thunkAPI) => {
    try {
      getToken();
      const response = await fetch(
        `${environment.baseURL}/api/getNewsAndInfoData`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateNewsInfoClicks =createAsyncThunk(
  "questions/updateNewsInfoClicks",
  async (id, thunkAPI) => {
    
    try {
      getToken();
      const response = await fetch(
        `${environment.baseURL}/api/updateNewsInfoClicks/${resultId}/${id}`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      

      if (response.status === 200 ) {
          return data
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
)

export const informationSlice = createSlice({
  name: "information",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    newsData: []
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
    clearData: (state) => {
      state.newsData = [];

      return state;
    }
  },
  extraReducers: {
    [getNewsAndInfoData.fulfilled]: (state, { payload }) => {
     
      state.isFetching = false;
      state.newsData = payload;
    },
    [getNewsAndInfoData.pending]: (state) => {
      state.isFetching = true;
    },
    [getNewsAndInfoData.rejected]: (state, { payload }) => {
      state.isFetching = false; 
      state.isError = true;
      state.errorMessage = payload.Message;
    },
    [updateNewsInfoClicks.fulfilled]: (state,{payload}) => {
       state.isFetching = false;
       console.log("dd")
    },
    [updateNewsInfoClicks.pending]: (state,{payload}) => {
       state.isFetching = true;
    },
    [updateNewsInfoClicks.rejected]: (state, { payload }) => {
     
      state.isFetching = false;
      state.isError = true;
     
    },

  },
});

export const { clearState,clearData } = informationSlice.actions;

export const infoSelector = (state) => state.information;