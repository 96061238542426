import React, { useState } from 'react'
import { useEffect } from 'react';
import './Result_popup.css'



const Result_popup = ({ isresultPopup, setResultPopup, resultData, percentile }) => {
    const [hrs, setHrs] = useState('0');
    const [min, setMin] = useState('0');
    const [sec, setSec] = useState('0');

    useEffect(() => {
        if (resultData == null) {
            return;
        }
        let duration = parseInt(resultData?.totalTimeTaken);
        var seconds = Math.floor(( duration/ 1000) % 60),
            minutes = Math.floor(( duration/ (1000 * 60)) % 60),
            hours = Math.floor(( duration/ (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        setHrs(hours);
        setMin(minutes);
        setSec(seconds);
    }, [resultData])

    if (resultData == null) {
        return;
    }

    return (
        <div className={`popup_start result_popup ${isresultPopup ? '' : 'hidepopup'}`}>
            <div className="popup_wrapper">
                <div className="popup">

                    <div className="dashheader">
                        <h3 className="heading-4">Result view</h3>
                        <button onClick={() => setResultPopup(false)} className="close"><img src="/assets/img/icons/X.png" alt="" /></button>
                    </div>

                    {resultData && (<div className="result_partition_wrapper">

                        <div className="newResultWrapper">

                            <div class="score_board mt20">
                                <div class="result_header">
                                    <h3 class="heading-4">Final Score</h3>
                                </div>
                                <div class="score_board_progress">
                                    <div class="score_board_details">
                                        <p class="text-3 text-darkblue">Total Score</p>
                                        <h3 class="heading-3">{resultData?.totalScorePercent}%</h3>
                                    </div>
                                    <div class="progress_bar mt10">
                                        <div class="progress" style={{ width: resultData?.totalScorePercent + '%' }}></div>
                                    </div>
                                </div>
                                <div className="percent-score-box">
                                    {/* <div className="icon-percent">
                                    <p className="text-3"><strong>Median Score</strong> (% Percentage)</p>
                                    <span className="box-percent">35</span>
                                </div> */}
                                    {percentile !== null && <div className="icon-percent">
                                        <p className="text-3"><strong>Percentile</strong> (% Percentage)</p>
                                        <span className="box-percent yellow-box">{percentile.toFixed(2)}%</span>
                                    </div>}
                                </div>
                            </div>

                            {/* <div className="decission-mid">

                            <table class="result_table mt20">
                                <thead>
                                    <th class="text-left">Decision Round Score(%)</th>
                                    <th class="text-right">60%</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-left"><strong>Median Score</strong> (%)</td>
                                        <td class="text-right"><span className="darkbluebold">35</span></td>
                                    </tr>
                                    <tr>
                                        <td class="text-left"><strong>Percentile</strong> (%)</td>
                                        <td class="text-right"><span className="darkbluebold">17</span></td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="result_table mt15">
                                <thead>
                                    <th class="text-left">Resolution Round Score (%)</th>
                                    <th class="text-right">60%</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-left"><strong>Median Score</strong> (%)</td>
                                        <td class="text-right"><span className="darkbluebold">35</span></td>
                                    </tr>
                                    <tr>
                                        <td class="text-left"><strong>Percentile</strong> (%)</td>
                                        <td class="text-right"><span className="darkbluebold">17</span></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div> */}

                            <div class="score_board mt20">
                                <div class="result_header">
                                    <h3 class="heading-4">Time Taken</h3>
                                </div>
                                <div class="score_board_progress time-taken-box">
                                    <div className='time Hours'>
                                        {hrs.toString().split('').map((x => <span>{x}</span>))}
                                        <p className="text-3">Hours</p>
                                    </div>
                                    <span className="colon">:</span>
                                    <div className='time Minutes'>
                                        {min.toString().split('').map((x => <span>{x}</span>))}
                                        <p className="text-3">Minutes</p>
                                    </div>
                                    <span className="colon">:</span>
                                    <div className='time Seconds'>
                                        {sec.toString().split('').map((x => <span>{x}</span>))}
                                        <p className="text-3">Seconds</p>
                                    </div>
                                </div>
                                {/* <div className="percent-score-box">
                                <div className="icon-percent">
                                    <p className="text-3"><strong>Median Score</strong></p>
                                    <span className="box-percent">50 mins</span>
                                </div>
                                <div className="icon-percent">
                                    <p className="text-3"><strong>Percentile</strong></p>
                                    <span className="box-percent yellow-box">10 mins</span>
                                </div>
                            </div> */}
                            </div>

                        </div>
                    </div>)}

                </div>
            </div>
        </div>
    )
}

export default Result_popup