import React, { useState } from "react";
import ErrorToast from "../../Components/Toast/ErrorToast";
import "./DecisionRoundStyle.css";
import DragDropComponent from "./DragDropComponent";

const ParticularQuestionComponent = ({ show, setCurrQuestion, answer3, setAnswer3, questionsData, onSubmit }) => {
  const [showErr, setShowErr] = useState(false);
  const [activeidx, setActiveIdx] = useState(0);

  const setAns = (val, i, decisionQuestionId) => {
    console.log("decisionQuestionId", decisionQuestionId);
    console.log("answer " + i, val);
    setAnswer3((ans) => {
      if(ans == null || ans?.length == 0){
        ans = [{ decisionQuestionId, ...val }];
        return ans;
      }
      ans[i] = { decisionQuestionId, ...val }
      return ans;
    });
  }

  const onNextClick = (i) => {
    
    console.log(answer3);
    if(answer3 ==null || answer3[i]?.position1 == '' || answer3[i]?.position2 == '' ||answer3[i]?.position3 == ''){
      setShowErr(true);
      return;
    } 

    setActiveIdx(() => ++i);
  }

  return (
    <>
      {/* toast */}
      <ErrorToast show={showErr} message = {"Please fill all 3 the positions"} setShow={setShowErr}/>

      <div className={`decision-wrapper with_question ${show ? '' : 'hide'}`}>
        <div className="decision-header">
          <h3 className="heading-6 text-darkblue">
            Problem Resolution
          </h3>
        </div>

        {questionsData && questionsData.map((quest, i) => (
          <React.Fragment key={quest.id}>
            <DragDropComponent show={activeidx == i} options={quest.answerOptions} question={quest.decisionTitle}
              setAnswer={(val) => setAns(val, i, quest._id)} />
            {activeidx == i && (<div className="decision-buttons multiple-btn">
              {i == 0 ? (<button className="blue-Submit" onClick={() => {setCurrQuestion('question2'); }}>Back</button>)
                : (<button className="blue-Submit" onClick={() => setActiveIdx((i) => --i)}>Back</button>)}
              {i == (questionsData.length - 1) ? (<button className="blue-Submit" onClick={onSubmit}>Submit</button>)
                : (<button className="blue-Submit" onClick={() => onNextClick(i)}>Next</button>)}
            </div>)}
          </React.Fragment>
        )
        )}


      </div>
    </>
  );
}

export default ParticularQuestionComponent;
