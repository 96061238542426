import React, { Component, useState } from 'react'
import Sidemenu from '../../../Components/SideMenu/Sidemenu';
import DashboardHeader from '../../../Components/Dashboard_Header/DashboardHeader';
import './AdminDashboardStyle.css';
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { adminSelector, getDashBoardData } from '../../../reducers/adminSlice';
import { useSelector } from 'react-redux';
import PageLoader from '../../../Components/loader/PageLoader';
import ScorePopup from '../../Popups/ScorePopup/ScorePopup';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import DatePopup from '../../Popups/DatePopup/DatePopup';
import moment from 'moment';

// import { Chart } from "react-google-charts";

// export const data = [
//   ["Year", "Sales", "Expenses"],
//   ["2004", 1000, 400],
//   ["2005", 1170, 460],
//   ["2006", 660, 1120],
//   ["2007", 1030, 540],
// ];

// export const options = {
//   title: "Company Performance",
//   curveType: "function",
//   legend: "none",
// };

const AdminDashboard = () => {

  function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return (hours > 0 ? hours + " hrs " : '') + (minutes > 0 ? minutes + " mins " : '') + (seconds > 0 ? seconds + " secs " : "");
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashBoardData());
  }, []);

  const getMonthVal = (x) => {
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return month[x + 1];
  }

  let { isFetching,
    isSuccess,
    isError,
    errorMessage,
    dashBoardData } = useSelector(adminSelector);


  // Graph Data


  const completedGame = dashBoardData?.graphData?.map(res => ({
    x: res?._id?.week,
    y: res.completedGame
  }))
  const droppedOut = dashBoardData?.graphData?.map(res => ({
    x: res?._id?.week,
    y: res.droppedOut
  }))
  const startedGame = dashBoardData?.graphData?.map(res => ({
    x: res?._id?.week,
    y: res.startedGame
  }))
  const totalRegistered = dashBoardData?.graphData?.map(res => ({
    x: res?._id?.week,
    y: res.totalRegistered
  }))

  const newOptions = {
    options: {
      legend: {
        position: 'top',
        horizontalAlign: 'right',
      },
      chart: {
        id: "User Analytics",
        toolbar: {
          show: false
        },
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: 'User Analytics',
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: 'inter-regular',
          color: '#263238'
        },
      }
      // xaxis: {
      //   categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
      // }
    },
    series: [
      {
        name: "Total Registered",
        data: totalRegistered
      },
      {
        name: "completed Game",
        data: completedGame
      },
      {
        name: "Started Game",
        data: startedGame
      },
      {
        name: "Dropped Out",
        data: droppedOut
      }
    ]
  }

  // End Graph Data
  const [popupVisible, setPopupVisible] = useState(false);
  const [isDatePopup, setDatePopup] = useState(false);

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const [values, setValues] = useState({ min: 0, max: 100 })

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);


  const handleInput = (e) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };

  const onClickApply = () => {
    const obj = {
      min: minValue,
      max: maxValue
    }
    setValues(val => ({ ...val, min: minValue, max: maxValue }))
    dispatch(getDashBoardData(obj));
    setPopupVisible(false)
  }

  const handleClear = () => {
    setMinValue(0);
    setMaxValue(100)
    setValues(val => ({ ...val, min: 0, max: 100 }))
    const obj = {
      min: 0,
      max: 100
    }
    dispatch(getDashBoardData(obj));

  }

  const onClickApplyDate = (val) => {
    console.log(val);
    setMinDate(moment(val[0]).format('YYYY-MM-DD'));
    setMaxDate(moment(val[1]).format('YYYY-MM-DD'));

    const obj = {
      minDate: moment(val[0]).format('YYYY-MM-DD'),
      maxDate: moment(val[1]).format('YYYY-MM-DD')
    }
    dispatch(getDashBoardData(obj));
    setDatePopup(false)
  }

  const handleClearDate = () => {
    setMinDate(null);
    setMaxDate(null);

    const obj = {
      minDate: "",
      maxDate: ""
    }
    dispatch(getDashBoardData(obj));
  }

  return (
    <>
      {isFetching && (<PageLoader />)}
      <Sidemenu />
      <DatePopup isDatePopup={isDatePopup} setDatePopup={setDatePopup} minDate={minDate} maxDate={maxDate} onClickApplyDate={onClickApplyDate} />
      <ScorePopup show={popupVisible} setShow={setPopupVisible} minValue={minValue} maxValue={maxValue} onChange={handleInput} onApply={onClickApply} />
      <DashboardHeader title="Dashboard" />

      <div className="admindashboard-wrapper">

        <div className="admin-dash-top">

          <div class="datasetnav">
            <h3 class="heading-4 text-darkblue">Overview</h3>
            {/* <div class="data_filters">
              <div class="drop_selector white year">
                <select>
                  <option value="">Month-Year</option>
                  <option value=""></option>
                  <option value=""></option>
                </select>
              </div>
            </div> */}
          </div>

        </div>

        <div className="admin-data-wrapper">

          <div className="admin-data">
            {/* <img src="/assets/img/demo_graph.png" alt="" /> */}
            {/* <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={data}
              options={options}
              legendToggle
            /> */}

            <div className="mixed-chart">
              <ReactApexChart
                options={newOptions.options}
                series={newOptions.series}
                type="line"
                width="100%"
                height="350px"
              />
            </div>

          </div>

        </div>

        <div className="filter-tab mt15">

          <div className="availableFilters">

            {(values.min != 0 || values.max != 100) && <span className="filterspan">
              <p>Score: <strong className='text-darkblue'>{minValue}-{maxValue}%</strong></p>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClear}>
                <circle cx="6.5" cy="6.5" r="6.1" fill="white" stroke="#CCCCCC" stroke-width="0.8" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.83457 5.13983C8.91473 5.05967 8.91473 4.92971 8.83457 4.84955C8.75441 4.76939 8.62445 4.76939 8.54429 4.84955L6.84206 6.55177L5.13983 4.84955C5.05967 4.76939 4.92971 4.76939 4.84955 4.84955C4.76939 4.92971 4.76939 5.05967 4.84955 5.13983L6.55177 6.84206L4.84955 8.54429C4.76939 8.62445 4.76939 8.75441 4.84955 8.83457C4.92971 8.91473 5.05967 8.91473 5.13983 8.83457L6.84206 7.13235L8.54429 8.83457C8.62445 8.91473 8.75441 8.91473 8.83457 8.83457C8.91473 8.75441 8.91473 8.62445 8.83457 8.54429L7.13235 6.84206L8.83457 5.13983Z" fill="#404040" />
              </svg>
            </span>}

            {(minDate != null || maxDate != null) && <span className="filterspan">
              <p>Date: <strong className='text-darkblue'>{minDate} - {maxDate}</strong></p>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClearDate}>
                <circle cx="6.5" cy="6.5" r="6.1" fill="white" stroke="#CCCCCC" stroke-width="0.8" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.83457 5.13983C8.91473 5.05967 8.91473 4.92971 8.83457 4.84955C8.75441 4.76939 8.62445 4.76939 8.54429 4.84955L6.84206 6.55177L5.13983 4.84955C5.05967 4.76939 4.92971 4.76939 4.84955 4.84955C4.76939 4.92971 4.76939 5.05967 4.84955 5.13983L6.55177 6.84206L4.84955 8.54429C4.76939 8.62445 4.76939 8.75441 4.84955 8.83457C4.92971 8.91473 5.05967 8.91473 5.13983 8.83457L6.84206 7.13235L8.54429 8.83457C8.62445 8.91473 8.75441 8.91473 8.83457 8.83457C8.91473 8.75441 8.91473 8.62445 8.83457 8.54429L7.13235 6.84206L8.83457 5.13983Z" fill="#404040" />
              </svg>
            </span>}

          </div>

          <div className="filter-btn-wrapper">

            <button className='icon-buttons' onClick={() => setPopupVisible(true)}>
              Score
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.71875 3.71875V7.37748C3.71875 10.0138 5.82746 12.1991 8.46375 12.2186C9.09466 12.2234 9.72029 12.1033 10.3046 11.8651C10.8888 11.627 11.4202 11.2756 11.868 10.8311C12.3158 10.3867 12.6713 9.85802 12.9138 9.27558C13.1564 8.69313 13.2812 8.06843 13.2812 7.4375V3.71875C13.2812 3.57785 13.2253 3.44273 13.1257 3.3431C13.026 3.24347 12.8909 3.1875 12.75 3.1875H4.25C4.1091 3.1875 3.97398 3.24347 3.87435 3.3431C3.77472 3.44273 3.71875 3.57785 3.71875 3.71875Z" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.375 14.875H10.625" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.5 12.2188V14.875" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.1626 8.5H13.8123C14.3759 8.5 14.9164 8.27612 15.3149 7.8776C15.7135 7.47909 15.9373 6.93859 15.9373 6.375V5.3125C15.9373 5.1716 15.8814 5.03648 15.7817 4.93685C15.6821 4.83722 15.547 4.78125 15.4061 4.78125H13.2811" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.84931 8.5H3.1792C2.61561 8.5 2.07511 8.27612 1.6766 7.8776C1.27808 7.47909 1.0542 6.93859 1.0542 6.375V5.3125C1.0542 5.1716 1.11017 5.03648 1.2098 4.93685C1.30943 4.83722 1.44455 4.78125 1.58545 4.78125H3.71045" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>

            <button className='icon-buttons' onClick={() => setDatePopup(true)}>
              Date
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.8125 2.65625H3.1875C2.8941 2.65625 2.65625 2.8941 2.65625 3.1875V13.8125C2.65625 14.1059 2.8941 14.3438 3.1875 14.3438H13.8125C14.1059 14.3438 14.3438 14.1059 14.3438 13.8125V3.1875C14.3438 2.8941 14.1059 2.65625 13.8125 2.65625Z" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.6875 1.59375V3.71875" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.3125 1.59375V3.71875" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.65625 5.84375H14.3438" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.10938 8.49976H7.96875L6.90625 9.82788C7.08095 9.82788 7.25296 9.87096 7.40704 9.9533C7.56112 10.0356 7.69252 10.1547 7.7896 10.3C7.88668 10.4452 7.94645 10.6121 7.96361 10.786C7.98077 10.9599 7.9548 11.1353 7.888 11.2967C7.82119 11.4581 7.71561 11.6006 7.58061 11.7114C7.4456 11.8223 7.28534 11.8982 7.11401 11.9324C6.94268 11.9665 6.76557 11.9579 6.59836 11.9073C6.43116 11.8567 6.27902 11.7656 6.15541 11.6421" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.5625 9.29663L10.625 8.49976V11.9529" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </div>

        </div>

        {(dashBoardData?.roundData.length > 0 || dashBoardData?.score.length > 0) && (<div className="admin-boxes-wrapper mt15">

          <div className="admin-boxes">
            <div className='box-header'>
              <p className="text-3">Time Spend</p>
            </div>
            <div className="box-mid mt5">
              <p className="text-3 text-darkblue">
                Average Time Spend
              </p>
              <h4 className='heading-5 mt5'>
                <img src="/assets/img/average_time.png" alt="" />
                {dashBoardData !== null && msToTime(dashBoardData.roundData.reduce((ac, ob) => { if (ac.avgTime !== null) { return ac + ob.avgTime } }, 0))}
              </h4>
            </div>
            <div className="box-bottom">
              <table>
                <tbody>
                  {dashBoardData !== null && dashBoardData.roundData.map((das, idx) => (
                    <tr className={idx !== 0 ? 'mt10' : ''}>
                      <td className='text-left'>Round {das._id}</td>
                      <td className='text-right'><strong>{das.avgTime ? msToTime(das.avgTime) : '-'}</strong></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="admin-boxes">
            <div className='box-header'>
              <p className="text-3">Clicks</p>
            </div>
            <div className="box-mid mt5 green-data">
              <p className="text-3 text-darkblue">
                Total Clicks
              </p>
              <h4 className='heading-5 mt5'>
                <img src="/assets/img/average_clicks.png" alt="" />
                {dashBoardData !== null && dashBoardData.roundData.reduce((ac, ob) => { return ac + ob.totalClicks }, 0)}
              </h4>
            </div>
            <div className="box-bottom">
              <table>
                <tbody>
                  {dashBoardData !== null && dashBoardData.roundData.map((das, idx) => (
                    <tr className={idx !== 0 ? 'mt10' : ''}>
                      <td className='text-left'>Round {das._id}</td>
                      <td className='text-right'><strong>{das.totalClicks ? (das.totalClicks) : '-'}</strong></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="admin-boxes">
            <div className='box-header'>
              <p className="text-3">Score</p>
            </div>
            <div className="box-mid mt5 yellow-data">
              <p className="text-3 text-darkblue">
                Average Score
              </p>
              <h4 className='heading-5 mt5'>
                <img src="/assets/img/average_score.png" alt="" />
                {dashBoardData !== null && dashBoardData?.score[0]?.avgScore}
              </h4>
            </div>
            <div className="box-bottom">
              <table>
                <tbody>
                  <tr>
                    <td className='text-left'>Round 1</td>
                    <td className='text-right'><strong>-</strong></td>
                  </tr>
                  <tr className='mt10'>
                    <td className='text-left'>Round 2</td>
                    <td className='text-right'><strong>-</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="admin-boxes">
            <div className='box-header'>
              <p className="text-3">Attempts</p>
            </div>
            <div className="box-mid mt5 red-data">
              <p className="text-3 text-darkblue">
                Average Attempts
              </p>
              <h4 className='heading-5 mt5'>
                <img src="/assets/img/average_attempts.png" alt="" />
                {dashBoardData !== null && dashBoardData.score[0].avgAttempt}
              </h4>
            </div>
            <div className="box-bottom">
              <table>
                <tbody>
                  <tr>
                    <td className='text-left'>Round 1</td>
                    <td className='text-right'><strong>-</strong></td>
                  </tr>
                  <tr className='mt10'>
                    <td className='text-left'>Round 2</td>
                    <td className='text-right'><strong>-</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>)}

      </div>

    </>
  )
}

export default AdminDashboard 