import React, { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import './DatePopupStyle.css';
import { useEffect } from 'react';

const DatePopup = ({ isDatePopup, setDatePopup, minDate, maxDate, onClickApplyDate }) => {

    const [value, onChange] = useState([new Date(), new Date()]);
    const [isopen, setOpen] = useState(false);

    // useEffect(() => {
    //     if (minDate !== null && maxDate !== null) {
    //         onChange([minDate, maxDate]);
    //     }
    // }, [minDate, maxDate])

    return (
        <div className={`popup_start scorePopup datePopup ${isDatePopup ? 'open' : ''}`}>
            <div className="popup_wrapper">
                <div className="popup">

                    <div class="dashheader">
                        <h3 class="heading-4">Score (% Percentage)</h3>
                        <button class="close" onClick={() => setDatePopup(false)}><img src="/assets/img/icons/X.png" alt="" /></button>
                    </div>

                    <div className={`popup-body ${isopen ? 'new-height' : ''}`}>
                        <DateRangePicker calendarIcon onChange={onChange} value={value} onCalendarOpen={() => setOpen(prev => !prev)} onCalendarClose={() => setOpen(false)} />
                        {/* <input type="datetime-local" /> */}
                    </div>

                    <div className="popup-footer">
                        <div class="start_round_buttton">
                            <button class="table_btn_fill" onClick={() => { onClickApplyDate(value)}}>Apply</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default DatePopup