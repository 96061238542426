import React, { useEffect, useState } from "react";
import "./DecisionRoundStyle.css";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const DragDropComponent = (props) => {
  //   const dispatch = useDispatch();
  const [questionOptions, setQuestionOptions] = useState([]);
  const [isItemDragged, setItemDragged] = useState(false);

  useEffect(() => {
    setQuestionOptions(props?.options);
  }, [props?.options]);


  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    setFilteredData({
      0: {
        name: props?.question ?? "Options",
        items: questionOptions,
      },
      1: {
        name: "Position1",
        items: [],
      },
      2: {
        name: "Position2",
        items: [],
      },
      3: {
        name: "Position3",
        items: [],
      },
    });
  }, [questionOptions]);

  useEffect(() => {
    setColumns(filteredData);
  }, [filteredData]);

  const [columns, setColumns] = useState(filteredData);

  console.log("************", columns);

  useEffect(() => {
    if (Object.keys(columns).length === 0) {
      return;
    }
    let pos1 = columns[1]?.items;
    let pos2 = columns[2]?.items;
    let pos3 = columns[3]?.items;

    let position1 = pos1[0]?._id ?? '';
    let position2 = pos2[0]?._id ?? '';
    let position3 = pos3[0]?._id ?? '';

    props?.setAnswer({ position1, position2, position3 })
  }, [columns]);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      let sourceItems = [...sourceColumn.items];
      let destItems = [...destColumn.items];

      console.log("what is sourceColumn", sourceColumn);

      console.log("what is destColumn", destColumn);

      const [removed] = sourceItems.splice(source.index, 1);

      if (destColumn?.items?.length > 0 && (destination.droppableId == '1' || destination.droppableId == '2' ||
        destination.droppableId == '3')) {
        let removedItem = destColumn?.items[0];
        destItems.splice(0, 1);
        sourceItems.splice(0, 0, removedItem);
        destItems.splice(0, 0, removed);
      } else {
        destItems.splice(destination.index, 0, removed);

      }

      setColumns((cols) => {
        return {
          ...cols,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        }
      });
    } else {
      console.log("inside else condition");

      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns((cols) => {
        return {
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        }
      });
    }

    setItemDragged(false);
  };

  return (
    <>
      <DragDropContext
        onDragStart={() => setItemDragged(true)}
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([id, column]) => {
          return (
            <div className={`adjust-design ${(props?.show == undefined || props?.show) ? '' : 'hide'}`}>
              <Droppable droppableId={id} key={id}>
                {(provided, snapshot) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="decision-questions"
                    >
                      <div
                        className="decision-question-left"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      //   style={{ maxWidth: snapshot.isDraggingOver ? 'max-content' : '300px' }}
                      >
                        <div className="question_list">
                          {/* {column.name !== "Options" && ( */}
                          <div className="position">
                            <div className="position_heading">
                              <p className="text-3">{column.name}</p>
                            </div>
                            {/* {column?.items?.length === 0 && ( */}

                            {/* {has_answer} niche add krna hai */}
                            <div className="position_answer mt5">

                              {column?.items?.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item?._id}
                                    draggableId={item?._id.toString()}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <li
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                          className={`mt10${isItemDragged ? "ondragitem" : ""
                                            }`}
                                        >
                                          <input
                                            type="radio"
                                            id="pointone"
                                            name="#"
                                            value="#"
                                          />
                                          <label
                                            className="checkmark"
                                            for="pointone"
                                          >
                                            {item?.decisionTitle ?? item?.ansTitle}
                                          </label>
                                        </li>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}

                              {/* <p className="text-3">Darg Answers here</p> */}
                            </div>
                            {/* )} */}
                          </div>
                          {/* )} */}

                          {provided.placeholder}
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Droppable>
            </div>
          );
        })}
      </DragDropContext>
    </>
  );
};

export default DragDropComponent;
