import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLoader from '../../../Components/loader/PageLoader';
import ErrorToast from '../../../Components/Toast/ErrorToast';
import { adminSelector, clearState, loginadmin } from "../../../reducers/adminSlice";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
//   const [islogin, set_Islogin] = useState(true);
//   const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

//   //   input validation states
  const [validateEmail, setEmailValidation] = useState(false);
  const [validatePassword, setPasswordValidation] = useState(false);

//   //   Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(adminSelector);

//   const handleUserNameInput = (e) => {
//     let name = e.target.value;
//     setUserName(name);
//   };

  const handleUserEmailInput = (e) => {
    let mEmailAddress = e.target.value;
    setUserEmail(mEmailAddress);

    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (mEmailAddress === "") {
      setEmailValidation(true);
    } else if (!filter.test(mEmailAddress)) {
      setEmailValidation(true);
    } else {
      setEmailValidation(false);
    }
  };

  const handleUserPasswordInput = (e) => {
    let mPassword = e.target.value;
    setUserPassword(mPassword);

    if (mPassword === "") {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  };

//   const onClickSignupButton = (e) => {
//     e.preventDefault();
//     if (userName === "" || userEmail === "" || userPassword === "") {
//       console.log("if condition");
//     } else {
//       console.log("else condition");
//       var data = { name: userName, email: userEmail, password: userPassword };
//       dispatch(signupUser(data));
//     }
//   };

  const onClickSigninButton = (e) => {
    e.preventDefault();
    if (userEmail === "" || userPassword === "") {
      console.log("if condition");
      setIsShowError(true);
      setErrMessage("Both fields must not be empty !");
    } else {
      console.log("else condition");
      var data = { email: userEmail, password: userPassword };
      dispatch(loginadmin(data));
    }
  };

  useEffect(() => {
    if (isError) {
      // toast.error(errorMessage);
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      //   localStorage.setItem("isLoggedIn", "true");
      navigate("/admin/admindashboard", { replace: true });
    }
  }, [isError, isSuccess]);

  return (
    <div className="login-wrapper">
      {isFetching && <PageLoader />}

      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />

      <div className="flexbox">
        <div className="login-left">
          {/* {islogin && (<img src="../assets/img/homesignup.png" alt="" />) for reference}
                    {!islogin && (<img src="../assets/img/homesignin.png" alt="" />)} */}
          <img
            src="/assets/img/adminsignup.png"
            className={`transition`}
            alt=""
          />
          {/* <img
            src="../assets/img/homesignin.png"
            className={`transition ${islogin ? "dontshow" : ""}`}
            alt=""
          /> */}
        </div>
        <div className="login-right">
          <div className="login-header">
            <img src="/assets/img/pm_logo.png" alt="" />
          </div>
          <div className="form-wrapper">
            {/* login form */}
            <div className={`signup-form`}>
              <h3 className="heading-1 text-black">Welcome Back</h3>
              <p className="text-3 text-darkgrey mt10">
                Welcome Back! please enter your details.
              </p>
              <form
                action="#"
                method="POST"
                className="login-form mt40"
                autocomplete="off"
              >
                <div className="form-group">
                  <>
                    <label htmlFor="Email" className="text-3 text-black">
                      Email
                    </label>
                    <input
                      className="mt5 form-input"
                      type="email"
                      id="Email"
                      placeholder="Enter Your Email"
                      onChange={handleUserEmailInput}
                    />
                  </>

                  {validateEmail && (
                    <span className="msg-error mt10" id="nameValidation">
                      Enter valid email address
                    </span>
                  )}
                </div>
                <div className="form-group mt20">
                  <>
                    {" "}
                    <label htmlFor="Email" className="text-3 text-black">
                      Password
                    </label>
                    <input
                      className="mt5 form-input"
                      type="password"
                      id="password"
                      placeholder="Enter Your Password"
                      onChange={handleUserPasswordInput}
                    />
                  </>{" "}
                  {validatePassword && (
                    <span className="msg-error mt10" id="passValidation">
                      Enter valid password
                    </span>
                  )}
                </div>
                {/* <div className="form-options-wrapper mt20">
                  <div className="form-options">
                    <input type="checkbox" id="rememberme" name="rememberme" />
                    <label htmlFor="rememberme"> Remember Password</label>
                  </div>
                  <div className="form-options">
                    <a href="javascript:void" className="text-blue forget-link">
                      Forget Password
                    </a>
                  </div>
                </div> */}
                <div className="form-buttons-wrapper mt20">
                  <button
                    // type="submit"
                    className="blue-Submit"
                    onClick={onClickSigninButton}
                  >
                    Sign in
                  </button>
                  {/* <button className='mt20 google-signin'><img src="../assets/img/googleicon.png" alt="" /> Sign in with Google</button> */}
                </div>
              </form>
              {/* <div className="create-account mt20 text-center">
                <p className="text-3 text-black">
                  Don’t have an account?{" "}
                  <a
                    href="javascript:void"
                    onClick={() => set_Islogin(false)}
                    className="text-blue forget-link"
                  >
                    Sign up
                  </a>
                </p>
              </div> */}
            </div>
            {/* login form ends */}

            {/* Signup form */}
            {/* <div className={`signin-form ${islogin ? "hide" : ""}`}>
              <h3 className="heading-1 text-black">Welcome in PM Simulator</h3>
              <p className="text-3 text-darkgrey mt10">
                Welcome! please enter your details.
              </p>
              <form className="login-form mt40">
                <div className="form-group">
                  <label htmlFor="Name" className="text-3 text-black">
                    Name
                  </label>
                  <input
                    className="mt5 form-input"
                    type="text"
                    id="name"
                    placeholder="Enter Your Name"
                    onChange={handleUserNameInput}
                  />
                </div>
                <div className="form-group mt20">
                  <label htmlFor="Email" className="text-3 text-black">
                    Email
                  </label>
                  <input
                    className="mt5 form-input"
                    type="email"
                    id="email"
                    placeholder="Enter Your Email"
                    onChange={handleUserEmailInput}
                  />
                </div>
                <div className="form-group mt20">
                  <label htmlFor="Email" className="text-3 text-black">
                    Password
                  </label>
                  <input
                    className="mt5 form-input"
                    type="password"
                    id="password"
                    placeholder="Enter Your Password"
                    onChange={handleUserPasswordInput}
                  />
                </div>
                <div className="form-options-wrapper mt20">
                  <div className="form-options">
                    <input type="checkbox" id="rememberme" name="rememberme" />
                    <label htmlFor="rememberme"> Remember Password</label>
                  </div>
                </div>
                <div className="form-buttons-wrapper mt20">
                  <button className="blue-Submit" onClick={onClickSignupButton}>
                    Sign up
                  </button> */}
                  {/* <button className='mt20 google-signin'><img src="../assets/img/googleicon.png" alt="" /> Sign Up with Google</button> */}
                {/* </div>
              </form>
              <div className="create-account mt20 text-center">
                <p className="text-3 text-black">
                  Already an user?{" "}
                  <a
                    href="javascript:void"
                    onClick={() => set_Islogin(true)}
                    className="text-blue forget-link"
                  >
                    Sign in
                  </a>
                </p>
              </div>
            </div> */}

            {/* Signup form ends */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
