import React from 'react'
import Sidemenu from '../../../Components/SideMenu/Sidemenu';
import DashboardHeader from '../../../Components/Dashboard_Header/DashboardHeader';
import './UserListStyle.css';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { adminSelector, getAllUsers } from '../../../reducers/adminSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageLoader from "../../../Components/loader/PageLoader";


const UserList = () => {

    const dispatch = useDispatch();

    const navigate =  useNavigate();

    useEffect(() => {
        dispatch(getAllUsers())
    }, []);

    let {
        isFetching,
        isSuccess,
        isError,
        errorMessage,
        userlist,
    } = useSelector(adminSelector)

    console.log(userlist)

    return (
        <>
            {isFetching && (<PageLoader/>)}
            <Sidemenu />
            <DashboardHeader title="Users List" />

            <div className="admindashboard-wrapper userlist-wrapper">

                <div className="admin-dash-top">

                    <div class="datasetnav">
                        <h3 class="heading-4 text-darkblue">List of Users</h3>
                        <div class="data_filters">
                            <div class="drop_selector white year">
                                <select>
                                    <option value="Downtime">1-50</option>
                                    <option value="Error Rate">----</option>
                                    <option value="NPS">----</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="admin-data-wrapper datasettable datatable-admin">
                    <table class="result_table">
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>Email Id</th>
                                <th>No. of Round</th>
                                <th>No. of Attempts</th>
                                <th>Highest Score</th>
                                <th> </th>
                            </tr>
                        </thead>

                        <tbody>
                            {userlist.map((el) => (
                                <tr>
                                    <td>{el.name}</td>
                                    <td>{el.email}</td>
                                    <td>{el.answers[0]?.noOfRounds ?? 0}</td>
                                    <td>{el.answers[0]?.noOfAttempts ?? 0}</td>
                                    <td>{el.answers[0]?.maxScore ?? 0}%</td>
                                    {el.answers.length > 0 ? (<td className="table_btn_wrapper"> <button class="table_btn" onClick={()=> navigate('userdetail/' + el._id)}>View Detail</button> </td>): (<td className="table_btn_wrapper">N/A</td>)}
                                </tr>
                            ))}


                        </tbody>

                    </table>
                </div>

            </div>

        </>
    )
}

export default UserList