import React from 'react'
import Sidemenu from '../../../Components/SideMenu/Sidemenu';
import DashboardHeader from '../../../Components/Dashboard_Header/DashboardHeader';
import './UserDetailStyle.css';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { adminSelector, getparticularUserAns } from '../../../reducers/adminSlice';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from "../../../Components/loader/PageLoader";
import moment from "moment";
import { useState } from 'react';
import UserDetailsPopup from '../../../Components/UserDetailsPopup/UserDetailsPopup';
import TotalClickPopup from '../../../Components/TotalClickPopup/TotalClickPopup';
import ResultPop from '../../Popups/ResultPopup/ResultPop';

const UserDetail = () => {

    const dispatch = useDispatch();

    const [isUserDetailPop, setTimePop] = useState(false);

    const [isTotalClicksPop, setTotalClicksPop] = useState(false);

    const [roundNumber, setRoundNumber] = useState("")

    const [clickNumber, setClickNumber] = useState("");


    // const navigate =  useNavigate();

    let { userId } = useParams()

    const capitalizeFirst = str => {
        if (str == undefined) {
            return '';
        }
        let strArr = str.split(' ');
        let res = strArr.map((st) => st.charAt(0).toUpperCase());
        return res.join('')
    }

    useEffect(() => {
        dispatch(getparticularUserAns(userId))
    }, []);

    let {
        isFetching,
        isSuccess,
        isError,
        errorMessage,
        userlistparticular,
    } = useSelector(adminSelector)


    function msToTime(duration) {
        var milliseconds = Math.floor((duration % 1000) / 100),
            seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return (hours > 0 ? hours + " hrs " : '') + (minutes > 0 ? minutes + " mins " : '') + (seconds + " secs ");
    }

    const handleChange = (num) => {
        setTotalClicksPop(true)
        setRoundNumber(num)
    }

    const handleChangeTime = (nums) => {
        setTimePop(true)
        setClickNumber(nums);
    }

    const[resultVisible, setResultVisible] = useState(false);
    const[resultData, setResultData] = useState(null);


    return (
        <>
            {isFetching && (<PageLoader />)}

            <ResultPop show={resultVisible} setShow={setResultVisible} resultData={resultData}/>
            <UserDetailsPopup isShow={isUserDetailPop} close={() => setTimePop(false)} data={userlistparticular?.roundData} clickNumber={clickNumber} setClickNumber={setClickNumber} />
            <TotalClickPopup isShowClicks={isTotalClicksPop} closeClicks={() => setTotalClicksPop(false)} data={userlistparticular?.roundData} roundNumber={roundNumber} setRoundNumber={setRoundNumber} />
            <Sidemenu />
            <DashboardHeader title="User Details" />


            <div className="user-details-wrapper">

                <div className="user-details-left">

                    <div className="user-details-all">

                        <div className="name-section">

                            <div className="first-letters">
                                {userlistparticular !== null && capitalizeFirst(userlistparticular?.userData[0]?.name)}
                            </div>

                            <div className="full-name">
                                <p className="text-4">{userlistparticular !== null && userlistparticular?.userData[0].name}</p>
                                <p className="text-3 mt5">{userlistparticular !== null && userlistparticular?.userData[0].email}</p>
                            </div>

                        </div>

                        <div className="attempts-section">

                            <div className="attempts-details">
                                <p className="text-4">{userlistparticular !== null && (userlistparticular?.userData[0]?.answers[0]?.noOfAttempts ?? 0)}</p>
                                <p className="text-3 mt5">No. of Attempts</p>
                            </div>

                            <div className="attempts-details">
                                <p className="text-4">{userlistparticular !== null && (userlistparticular?.userData[0]?.answers[0]?.noOfRounds ?? 0)}</p>
                                <p className="text-3 mt5">Round Played</p>
                            </div>

                            <div className="attempts-details">
                                <p className="text-4">{userlistparticular !== null && Math.trunc(userlistparticular?.userData[0]?.answers[0]?.maxScore ?? 0)}%</p>
                                <p className="text-3 mt5">Highest Score</p>
                            </div>

                        </div>

                    </div>

                    <div className="user-details-time mt15">

                        <div className="admin-boxes">
                            <div className="box-mid mt5">
                                <p className="text-3 text-darkblue">Average Time Spend</p>
                                <h4 className="heading-5 mt5">
                                    <img src="/assets/img/average_time.png" alt="" />
                                    {userlistparticular !== null && msToTime(userlistparticular?.userData[0]?.answers[0]?.avgTimeTaken ?? 0)}
                                </h4>
                            </div>
                            <div className="box-bottom">
                                <table>
                                    <tbody>
                                        {userlistparticular?.roundData?.length > 0 && userlistparticular?.roundData.map((rd, idx) => (
                                            <tr className={`${idx !== 0 ? 'mt10' : ''}`}>
                                                <td className="text-left clickable" onClick={() => handleChangeTime(rd._id)}>Round {rd._id}</td>
                                                <td className="text-right"><strong>{rd.avgTime ? msToTime(rd.avgTime) : '-'}</strong></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    <div className="user-details-clicks mt15">

                        <div className="admin-boxes">
                            <div className="box-mid mt5 green-data">
                                <p className="text-3 text-darkblue">Total Clicks</p>
                                <h4 className="heading-5 mt5">
                                    <img src="/assets/img/average_clicks.png" alt="" />{userlistparticular !== null && userlistparticular?.roundData.reduce((ac, ob) => { return ac + ob.totalClicks }, 0)}</h4>
                            </div>
                            <div className="box-bottom">
                                <table>
                                    <tbody>
                                        {userlistparticular !== null && userlistparticular?.roundData.map((rd,idx) => (
                                            <tr className={`${idx !== 0 ? 'mt10' : ''}`}>
                                                <td className="text-left clickable" onClick={() => handleChange(rd._id)}>Round {rd._id}</td>
                                                <td className="text-right"><strong>{rd.totalClicks} Clicks</strong></td>
                                            </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="user-details-right">

                    <div className="admindashboard-wrapper userlist-wrapper">

                        <div className="admin-dash-top">

                            <div className="datasetnav">
                                <h3 className="heading-4 text-darkblue">List of Attempts</h3>
                                <div className="data_filters">
                                    <div className="drop_selector white year">
                                        <select>
                                            <option value="Downtime">1-50</option>
                                            <option value="Error Rate">----</option>
                                            <option value="NPS">----</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="admin-data-wrapper datasettable datatable-admin">
                            {userlistparticular?.result.length > 0 ? (<table className="result_table">
                                <thead>
                                    <tr>
                                        <th>Attempts</th>
                                        <th>Date | Time</th>
                                        <th>Time Taken</th>
                                        <th>Score</th>
                                        <th> </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {userlistparticular?.result?.map((el, idx) => (
                                        <tr key={el._id}>
                                            <td>Attempt {idx + 1}</td>
                                            <td>{moment(el?.submittedAt).format("DD MMMM")}, {moment(el?.submittedAt).format("hh:mm A")}</td>
                                            {el?.totalTimeTaken ? (<td>{msToTime(el?.totalTimeTaken)} </td>) : (<td>-</td>)}
                                            <td>{el.totalScorePercent}%</td>
                                            <td className="table_btn_wrapper"><button className="table_btn" onClick={()=> {setResultVisible(true); setResultData(el)}}>View Result</button></td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>) : (<p>No data to show</p>)}
                        </div>

                    </div>

                </div>

            </div>

        </>
    )
}

export default UserDetail;