import React from 'react'
import './Text_Submitted_Popup_Style.css';

const Text_Submitted_Popup = ({onPlayAgain, onViewResult}) => {
  return (
    <div className={`popup_start start_round_popup`}>
            <div className="popup_wrapper">
                <div className="popup">

                    <div className="dashheader">
                        <h3 className="heading-4"><img src="../assets/img/icons/XCircle.png" alt="" /> Confirmation</h3>
                    </div>

                    <div className="start_round_wrapper">
                        <h2 className="text-3 mt20">Test Submitted successfully.</h2>

                        <div className="start_round_buttton">
                            <button className='table_btn' onClick={onViewResult}>View Result</button>
                            <button className='table_btn_fill' onClick={onPlayAgain}>Play Again</button>
                        </div>

                    </div>



                </div>
            </div>
        </div>
  )
}

export default Text_Submitted_Popup