import Chart from "react-google-charts";
import React, { useState, useEffect } from "react";
import DashboardHeader from "../../Components/Dashboard_Header/DashboardHeader";
import Sidemenu from "../../Components/SideMenu/Sidemenu";
import "./DataSetStyle.css";
import {
  getDataSetInfo,
  dataSelector,
  getMarketData,
  getDataSetFilters,
  clearData,updateDataSetClicks
} from "../../reducers/dataSetSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import PageLoader from "../../Components/loader/PageLoader";
import { MultiSelect } from "react-multi-select-component";

const DataSet = () => {
  const [isiinerboard, set_isinnerboard] = useState(false);
  const dispatch = useDispatch();
  const {
    isFetching,
    isError,
    errorMessage,
    dataSetOuterData,
    marketData,
    getFilterData,
  } = useSelector(dataSelector);
  const [outerData, setOuterData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [singleOuterData, setSingleOuterData] = useState("");
  const [index, setIndex] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [regionFilter, setRegionFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [marketingFilter, setMarketingFilter] = useState([]);
  const [newCategoryFilter, setNewCategoryFilter] = useState("")
  const [newMarketingFilter, setNewMarketingFilter] = useState("")
  const isTermOpen = localStorage.getItem("resultId")
  const [current,setCurrent] =useState("")


  

  useEffect(() => {
    dispatch(getDataSetInfo());
    dispatch(clearData());
  }, []);
  useEffect(() => {
    if (singleOuterData) {
      let obj = {
        dataSetId: singleOuterData?._id,
      };
      dispatch(getDataSetFilters(obj));
    }
  }, [singleOuterData]);

  useEffect(() => {
    setOuterData(dataSetOuterData);
  }, [dataSetOuterData]);

  useEffect(() => {
    setGraphData(marketData);
  }, [marketData]);

  useEffect(() => {
    setFilterData(getFilterData);
  }, [getFilterData]);
  useEffect(() => {
    if (filterData?.region?.length > 0) {
      setRegionFilter(filterData?.region[0]);
    }
  }, [filterData]);

  //   const data = [
  //     ["Year", "Agoda", "Booking.com","Makemytrip"],
  //     ["Jan", 0, 4000,3000],
  //     ["Feb", 2000, 4500,3000],
  //     ["March", 3000, 4800,3000],
  //     ["April", 2800, 4900,3000],
  //     ["May", 1500, 3600,3000],
  //     ["June", 1500, 2500,3000],
  //     ["July", 2500, 1500,3000],
  //     ["August", 3500, 1700,3000],
  //     ["September", 4300, 2500,3000],
  //     ["Octoberber", 4800, 3000,3000],
  //     ["November", 4600, 2000,3000],
  //     ["December", 4000, 0,3000],
  //   ];

  function transpose(matrix) {
    return (
      matrix &&
      matrix[0]?.map((col, c) => matrix?.map((row, r) => matrix[r][c]))
    );
  }

  let newDataa;
  if (marketData?.length > 0) {
    newDataa = [
      [
        "Year",
        ...marketData[0]?.data?.map((g) => moment(g.month).format("MMM YYYY")),
      ],
    ];
  }

  marketData?.map((gr, i) => {
    newDataa[i + 1] = [gr._id?.subCategory ? gr._id?.subCategory : gr._id];
    gr?.data.map((d) => {
      newDataa[i + 1].push(d.marketShare ? d?.marketShare : d?.value);
    });
  });

  let data1 = transpose(newDataa);

  const options = {
    curveType: "function",
    legend: { position: "top" },
  };

  const [togglenavs, setTogleNavs] = useState(1);
  const toggletabs = ({ res, i }) => {

    console.log(i,"i")
    if(togglenavs !== i+1){
      setTogleNavs(i + 1);
      setIndex(i + 1);
      setSingleOuterData(res);
      setFilterData([])
      setCompanyFilter([])
      setCategoryFilter([])
      setMarketingFilter([])
     
      if(current!==res._id){setCurrent(res._id); dispatch(updateDataSetClicks(res._id))}

    }
  };

  console.log(index,"index")
  console.log(togglenavs,"togglenavs")
   
  const handleChange = ({ res, i }) => {
    dispatch(updateDataSetClicks(res._id))
    set_isinnerboard(true);
    setTogleNavs(i + 1);
    setIndex(i + 1);
    setSingleOuterData(res);
    setFilterData([])
  };

  const regionChange = (e) => {
    let data = e.target.value;
    setRegionFilter(data);
    let obj = {
      dataSetId: singleOuterData?._id,
      region: data,
    };
    // dispatch(getMarketData(obj));
    if (filterData?.company) {
      setCompanyFilter(
        filterData?.company?.map((res) => ({ label: res, value: res }))
      );
    }
    // if (filterData?.category?.length > 0) {
    //   setCategoryFilter(
    //     filterData?.category?.map((res) => ({ label: res, value: res }))
    //   );
    // }
    // if (filterData?.marketing) {
    //   setMarketingFilter(
    //     filterData?.marketing?.map((res) => ({ label: res, value: res }))
    //   );
    // }

    if (filterData?.category?.length > 0) {
      setNewCategoryFilter(filterData?.category[0])
    }
    if (filterData?.marketing) {
      setNewMarketingFilter(filterData?.marketing[0])
    }

  };

  const companyOption =
    filterData?.company?.length > 0 &&
    filterData?.company?.map((res) => ({ label: res, value: res }));

  const categoryOption =
    filterData?.category?.length > 0 &&
    filterData?.category?.map((res) => ({ label: res, value: res }));

  const marketingOption =
    filterData?.marketing?.length > 0 &&
    filterData?.marketing?.map((res) => ({ label: res, value: res }));

  useEffect(() => {
    if (filterData?.company) {
      setCompanyFilter(
        filterData?.company?.map((res) => ({ label: res, value: res }))
      );
    }
    // if (filterData?.category?.length > 0) {
    //   setCategoryFilter(
    //     filterData?.category?.map((res) => ({ label: res, value: res }))
    //   );
    // }
    // if (filterData?.marketing) {
    //   setMarketingFilter(
    //     filterData?.marketing?.map((res) => ({ label: res, value: res }))
    //   );
    // }
    if (filterData?.category?.length > 0) {
      setNewCategoryFilter(filterData?.category[0])
    }
    if (filterData?.marketing) {
      setNewMarketingFilter(filterData?.marketing[0])
    }
  }, [filterData]);

  const cOnChange = (e) => {
    let nData = e.map((res) => res.value);
    setCompanyFilter(e);
    let obj = {
      dataSetId: singleOuterData?._id,
      region: regionFilter,
      company: nData,
    };
    dispatch(getMarketData(obj));
  };
  const categoryOnChange = (e) => {
    setCategoryFilter(e);
    let nData = e.map((res) => res.value);
    if (marketingFilter?.length > 0) {
      let obj = {
        dataSetId: singleOuterData?._id,
        region: regionFilter,
        category: nData,
        marketing: marketingFilter?.map(res => res.value)
      };
      dispatch(getMarketData(obj));
    } else {
      let obj = {
        dataSetId: singleOuterData?._id,
        region: regionFilter,
        category: nData,
      };
      dispatch(getMarketData(obj));
    }
  };
  const marketingOnChange = (e) => {
    setMarketingFilter(e);
    let nData = e.map((res) => res.value);
    let obj = {
      dataSetId: singleOuterData?._id,
      region: regionFilter,
      category: categoryFilter?.map((res) => res.value),
      marketing: nData,
    };
    dispatch(getMarketData(obj));
  };

  const newCategoryOnChange = (e) => {
    let onChangeData = e.target.value
    setNewCategoryFilter(onChangeData)
    if (marketingFilter) {
      let obj = {
        dataSetId: singleOuterData?._id,
        region: regionFilter,
        category: onChangeData,
        marketing: newMarketingFilter
      };
      dispatch(getMarketData(obj));
    } else {
      let obj = {
        dataSetId: singleOuterData?._id,
        region: regionFilter,
        category: onChangeData,
      };
      dispatch(getMarketData(obj));
    }
  }

  const newMarketingOnChange = (e) => {
    let onChangeData = e.target.value
    setNewMarketingFilter(onChangeData)
    let obj = {
      dataSetId: singleOuterData?._id,
      region: regionFilter,
      category: newCategoryFilter,
      marketing: onChangeData,
    };
    dispatch(getMarketData(obj));
  }

  useEffect(() => {

    if (singleOuterData && regionFilter && filterData?.company?.length > 0) {
      let cData = filterData?.company?.map((res) => res);
      let obj = {
        dataSetId: singleOuterData?._id,
        region: regionFilter,
        company: cData,
      };
      dispatch(getMarketData(obj));
      console.log("inside company api");
    }
    // if (singleOuterData && regionFilter && filterData?.category?.length > 0 && filterData?.marketing?.length === 0) {
    //   let categoryData = filterData?.category?.map((res) => res);
    //   let obj = {
    //     dataSetId: singleOuterData?._id,
    //     region: regionFilter,
    //     category: categoryData,
    //   };
    //   dispatch(getMarketData(obj));

    //   console.log("inside category api");
    // }
    // if (
    //   singleOuterData && regionFilter && filterData?.category?.length > 0 &&
    //   filterData?.marketing?.length > 0 
    // ) {
    //   let categoryData = filterData?.category?.map((res) => res);
    //   let marketingData = filterData?.marketing?.map((res) => res);
    //   let obj = {
    //     dataSetId: singleOuterData?._id,
    //     region: regionFilter,
    //     category: categoryData,
    //     marketing: marketingData,
    //   };
    //   dispatch(getMarketData(obj));
    //   console.log("inside marketing and category api");
    // }


    if (singleOuterData && regionFilter && filterData?.category?.length > 0 && filterData?.marketing?.length === 0) {
      let categoryData = filterData?.category[0];
      let obj = {
        dataSetId: singleOuterData?._id,
        region: regionFilter,
        category: categoryData,
      };
      dispatch(getMarketData(obj));

      console.log("inside category api");
    }
    if (
      singleOuterData && regionFilter && filterData?.category?.length > 0 &&
      filterData?.marketing?.length > 0
    ) {
      let categoryData = filterData?.category[0];
      let marketingData = filterData?.marketing[0];
      let obj = {
        dataSetId: singleOuterData?._id,
        region: regionFilter,
        category: categoryData,
        marketing: marketingData,
      };
      dispatch(getMarketData(obj));
      console.log("inside marketing and category api");
    }


    // if (singleOuterData && regionFilter) {
    //   let obj;
    //   if (filterData?.company?.length > 0) {
    //     let cData = companyFilter?.map((res) => res.value);
    //     obj = {
    //       dataSetId: singleOuterData?._id,
    //       region: regionFilter,
    //       company: cData,
    //     };
    //     dispatch(getMarketData(obj));

    //     console.log("inside company api");
    //   }
    //   if (filterData?.category?.length > 0 && filterData?.marketing?.length === 0) {
    //     let categoryData = categoryFilter?.map((res) => res.value);
    //     obj = {
    //       dataSetId: singleOuterData?._id,
    //       region: regionFilter,
    //       category: categoryData,
    //     };
    //     dispatch(getMarketData(obj));

    //     console.log("inside category api");
    //   }
    //   if (
    //     filterData?.category?.length > 0 &&
    //     filterData?.marketing?.length > 0
    //   ) {
    //     let categoryData = categoryFilter?.map((res) => res.value);
    //     let marketingData = marketingFilter?.map((res) => res.value);
    //     obj = {
    //       dataSetId: singleOuterData?._id,
    //       region: regionFilter,
    //       category: categoryData,
    //       marketing: marketingData,
    //     };
    //     dispatch(getMarketData(obj));
    //     console.log("inside marketing and category api");
    //   }

    //   console.log("inside if condition");
    // }
  }, [
    singleOuterData,
    regionFilter,
    filterData,
    // companyFilter,
    // categoryFilter,
    // marketingFilter,
  ]);

  // console.log(marketData, "marketData");

  return (
    <>
      <Sidemenu />
      <DashboardHeader title="Data Set" />
      {isFetching && <PageLoader />}
      <div className={`newsSection ${isiinerboard ? "hide" : ""}`}>
        {outerData.length == 0 && (<div>
          "Not permitted to access data"
        </div>)}
        {outerData?.map((res, i) => (
          <div
            className="product"
            onClick={() => handleChange({ res, i })}
            key={i}
          >
            <div
              className="product-top"
              style={{ background: `url(${res.backgroundImg})` }}
            >
              <span className="notify">{res.type}</span>
              <img src={res.iconImg} alt="" className="icon" />
            </div>
            <div className="product-bottom">
              <p className="text-3">{res.name}</p>
              <span className="dots"></span>
            </div>
          </div>
        ))}
      </div>

      <div className={`newsExppore ${isiinerboard ? "" : "hide"}`}>
        <div className="innerSideMenu">
          <ul className="inner-side-menu">
            {outerData?.map((res, i) => (
              <li className={togglenavs == i + 1 ? "active" : ""} key={i}>
                <a onClick={() => toggletabs({ res, i })}>
                  {res.name}
                  <img src="/assets/img/icons/ArrowRight.png" alt="" />
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="innerboard-wrapper datasetboard_wrapper">
          {/* 5 */}
          <div
            className={`innerboard ${togglenavs == index ? "active-tab" : ""}`}
          >
            <div className="datasetnav">
              <h3 className="heading-3">
                {singleOuterData?.name}{" "}
                {/* <CSVLink
                  data={singleOuterData?.csvUrl}
                  filename={singleOuterData?.name}
                >
                  <img src="/assets/img/icons/ArrowLineDown.png" alt="" />
                </CSVLink> */}
                <a href={singleOuterData?.csvUrl}><img src="/assets/img/icons/ArrowLineDown.png" alt="" /></a>
              </h3>
              <div className="data_filters">
                <div className="drop_selector blue region">
                  <select
                    id="cars"
                    name="carlist"
                    form="carform"
                    value={regionFilter}
                    onChange={regionChange}
                  >
                    {/* <option>Region</option> */}
                    {filterData?.region &&
                      filterData?.region?.map((res) => (
                        <option value={res} key={res}>
                          {res}
                        </option>
                      ))}
                  </select>
                </div>
                {filterData?.company?.length > 0 && (
                  <MultiSelect
                    options={companyOption}
                    value={companyFilter}
                    onChange={cOnChange}
                    labelledBy="Select"
                  />
                )}
                {filterData?.category?.length > 0 && (
                  // <MultiSelect
                  //   options={categoryOption}
                  //   value={categoryFilter}
                  //   onChange={categoryOnChange}
                  //   labelledBy="Select"
                  // />

                  <div className="drop_selector white year">
                    <select value={newCategoryFilter} onChange={newCategoryOnChange}>
                      {
                        filterData?.category?.map(res => <option value={res}>{res}</option>)
                      }

                    </select>
                  </div>
                )}
                {filterData?.marketing?.length > 0 && (
                  // <MultiSelect
                  //   options={marketingOption}
                  //   value={marketingFilter}
                  //   onChange={marketingOnChange}
                  //   labelledBy="Select"
                  // />
                  <div className="drop_selector white year">
                    <select value={newMarketingFilter} onChange={newMarketingOnChange}>
                      {
                        filterData?.marketing?.map(res => <option value={res}>{res}</option>)
                      }

                    </select>
                  </div>
                )}
                {/* <div className="drop_selector white company">
                  <select id="cars" name="carlist" form="carform">
                    <option value="Not Selected">Country</option>
                    <option value="1">2</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>

                <div className="drop_selector white year">
                  <select id="cars" name="carlist" form="carform">
                    <option value="Not Selected">Month-Year</option>
                    <option value="1">2</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div> */}
              </div>
            </div>

            <div className="datasetgraph_wrapper">
              <div className="datasetgraph">
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height="280px"
                  data={data1}
                  options={options}
                />
              </div>
            </div>

            <div className="datasettable_wrapper">
              <div className="datasettable">
                <table>
                  <thead>
                    <th>{filterData?.company?.length > 0 ? "Company" : "Sub Category"}</th>
                    {marketData?.length > 0 &&
                      marketData[0]?.data.map((dt, i) => (
                        <th key={i}>{moment(dt.month).format("MMM YYYY")}</th>
                      ))}
                  </thead>
                  <tbody>
                    {marketData?.map((res, i) => (
                      <tr key={i}>
                        <td>
                          {res._id.subCategory ? res._id.subCategory : res._id}
                        </td>
                        {res?.data.map((dt, index) => (
                          <td key={index}>
                            {dt.marketShare ? dt?.marketShare : dt?.value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataSet
